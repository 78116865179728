import React, {useEffect, useState} from "react";
import {Box, Button, Modal, Typography} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";
import { AnimatedOtpInput } from "app/components/inputs";

const otpSchema = Yup.object().shape({
  otp: Yup.string().required("Otp is required"),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

type PageName = "notfound" | "invailQuery" | "otpVerify" | "tradeForm";

export const TradeWidget = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState<{
    [key in PageName]: boolean;
  }>({
    notfound: true,
    invailQuery: false,
    otpVerify: false,
    tradeForm: false,
  });
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("countdownTime");
    return savedTime ? parseInt(savedTime, 10) : 60;
  });

  const switchPage = (pageName: PageName) => {
    setCurrentPage((current) => ({
      ...Object.keys(current).reduce((acc, key) => {
        acc[key as PageName] = false; // Set all pages to false
        return acc;
      }, {} as { [key in PageName]: boolean }),
      [pageName]: true, // Set the specified page to true
    }));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(true);

  const showTimer = () => {
    if (timeLeft === 0) {
      return null;
    } else if (timeLeft < 10) {
      return "00:0" + timeLeft + "Sec";
    } else {
      return "00:" + timeLeft + "Sec";
    }
  };

  const sendOtp = () => {};

  const PageNotFound = () => {
    return (
      <Box>
        <Typography variant="h6" color="primary">Page Not Found!</Typography>
      </Box>
    );
  };
  const InvalidQueryParams = () => {
    return (
      <Box>
        <Typography variant="h6" color="primary">Invalid Link or Link is expired!</Typography>
        <Typography variant="body2" color="primary">Contact to Support Team </Typography>
        <Button>Go Back</Button>
      </Box>
    );
  };

  const TradeForm = () => {
    return (
        <Box>
            <Typography variant="h6" color="primary">Trade Form</Typography>
        </Box>
    )
  }

  const OtpVerify = () => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, handleSubmit: () => {}) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };
    setTimeLeft(60);
    return (
      <Box>
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={otpSchema}
          onSubmit={(values: any) => {
            console.log(values);
          }}
        >
          {({ errors, touched, handleChange, handleSubmit, values }: any) => (
            <Box 
              className={classes.formBox}
              onKeyDown={(event) => handleKeyDown(event, handleSubmit)}
            >
              <Typography
                variant="h3"
                align="center"
                className={classes.formHeading}
              >
                One time password
                <br /> verification
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                sx={{ fontWeight: "500" }}
              >
                We have sent a OTP to your email
              </Typography>
              <Box className={classes.otpBoxInput}>
                <AnimatedOtpInput
                  value={values.otp}
                  onChange={handleChange("otp")}
                  numInputs={6}
                  shouldAutoFocus={true}
                />
              </Box>
              {touched.otp && errors.otp && (
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={classes.errorMsg}
                >
                  {errors.otp}
                </Typography>
              )}
              <Box className={classes.btnsBox}>
                <Button
                  variant="outlined"
                  className={classes.submitBtnOutloined}
                >
                  Back
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Submit
                </Button>
              </Box>
              <Box className={classes.otpBox}>
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  className={
                    timeLeft < 1
                      ? classes.resentOtpText
                      : classes.resentOtpTextD
                  }
                  onClick={() => (timeLeft < 1 ? sendOtp() : null)}
                >
                  Resent OTP
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ margin: "auto 20px", width: "50px" }}
                >
                  {showTimer()}
                </Typography>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    );
  };
  const renderPage = () => {
    const activePage: any = Object.keys(currentPage).find(
      // @ts-ignore
      (key: any) => currentPage[key] === true
    );
    switch (activePage) {
      case "notfound":
        return <PageNotFound />;
      case "invailQuery":
        return <InvalidQueryParams />;
      case "otpVerify":
        return <OtpVerify />;
      case "tradeForm":
        return <TradeForm />;
      default:
        return <PageNotFound />; 
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {renderPage()}
      </Box>
    </Modal>
  );
};


const useStyles = makeStyles()((theme) => {
  return {
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    resentOtpTextD: {
      textDecorationLine: "underline",
      padding: "20px 0",
      color: "grey",
    },
    otpBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px 0",
      textTransform: "none",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    inputBoxes: {
      padding: "10px",
      margin: "20px 10px",
    },
    otpBoxInput: {
      ...styles.flexDRS,
      justifyContent: "center",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      position: "relative",
    },
  };
});

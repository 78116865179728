import React, {ReactElement} from "react";

import {MoneyhubPaymentView} from "./moneyhub-payment";
import {CryptohubPaymentView} from "./cryptohub-payment";
import { WallethubPaymentView } from "./wallethub-payment";
import { SelectPaymentMethod } from "./my-crypt";
import { CasinoDashboard } from "./casino";


const INTEGRATION_VIEWS_MAPPING: Record<string, ReactElement> = {
    "/moneyhub-payment": <MoneyhubPaymentView />,
    "/cryptohub-payment": <CryptohubPaymentView />,
    "/wallethub-payment": <WallethubPaymentView />,
    "/my-crypt": <SelectPaymentMethod />,
    "/casino": <CasinoDashboard />
};
export default INTEGRATION_VIEWS_MAPPING;

import React, {useEffect, useState} from "react";
import {Box, Button, Grid, TextField, Typography,} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";
import {getAxiosAPI, postAxiosAPI} from "app/utils/axiosApiRequests";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Formik} from "formik";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import * as Yup from "yup";
import dayjs from "dayjs";
import {SearchInput} from "app/components/inputs";
import {useAppContext} from "app/hooks";
import ComingSoonPage from "app/components/unsorted/CommingSoon";

const form_schema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    fullName: Yup.string().required("Full name is required"),
    dob: Yup.string()
        .required("Date of birth is required")
        .test("dob", "Member must be at least 18 years old", (value) => {
            const today = dayjs();
            const dob = dayjs(value);
            const age = today.diff(dob, "year");
            return age >= 18;
        }),
    country: Yup.string().required("Country is required"),

});

function generateRandomPassword(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
    }
    return password;
}

export const InviteTeamMembersView = ({ mdSize }: { mdSize: number }) => {
    // State for form fields
    const { classes } = useStyles();
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState<"success" | "error">("success");
    const {user} = useAppContext();



    // Handle form submission
    const handleAPIcall = async (values: any) => {

        setLoading(true);
        const birthDate = values.dob.split("T")[0];
        const random_password = generateRandomPassword(8);
        const payload = {
            birthday: birthDate,
            company: user.id(),
            country: values.country,
            email: values.email,
            full_name: values.fullName,
            password: random_password,
            confirm_password: random_password,
        };
        await postAxiosAPI(
            "/user/team-member",
            JSON.stringify(payload),
            true
        )
            .then((res: any) => {
                setLoading(false);
                console.log(res.data);
                if (res.data.code === 200) {
                    setShowSnackbar(true);
                    setSeverity("success");
                    setSnackbarMessage("Team Member Added successfully");

                } else {
                    setSeverity("error");
                }
            })
            .catch((err: any) => {
                setLoading(false);
                console.log(err);
            });
    };

    const getCountry = async () => {
        setLoading(true);
        await getAxiosAPI("/country")
            .then((res: any) => {
                setLoading(false);
                setCountries(res.data.data);
            })
            .catch((err: any) => {
                setLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        getCountry();
    }, []);

    if (user.isTeamMember()){
        return <ComingSoonPage title={'Oops!!'} description={"It seems you don't have access to this feature yet!. "}/>
    }
    return (
        <Box className={classes.mainBoxForm}>
            <Loader isLoading={loading} />
            <MessageAlert
                showBar={showSnackbar}
                message={snackbarMessage}
                severity={severity}
                onClose={() => setShowSnackbar(false)}
            />
            <Typography variant="h3" className={classes.formHeading}>
                Invite A Member To Your Organization
            </Typography>
            <Formik
                initialValues={{
                    email: "",
                    fullName: "",
                    dob: null,
                    country: "",
                }}
                validationSchema={form_schema}
                onSubmit={(values: any) => {
                    handleAPIcall(values);
                }}
            >
                {({
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      values,
                      setFieldValue,
                  }: any) => (
                    <Grid container columns={18}>
                        <Grid
                            item
                            sm={4}
                            md={9}
                            xs={18}
                            className={classes.gridItemPadding}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                className={classes.inputBox}
                                value={values.email}
                                onBlur={handleBlur("email")}
                                onChange={handleChange("email")}
                            />
                            {touched.email && errors.email && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.email}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            sm={4}
                            md={9}
                            xs={18}
                            className={classes.gridItemPadding}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                className={classes.inputBox}
                                value={values.fullName}
                                onBlur={handleBlur("fullName")}
                                onChange={handleChange("fullName")}
                            />
                            {touched.fullName && errors.fullName && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.fullName}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            sm={4}
                            md={9}
                            xs={18}
                            className={classes.gridItemPadding}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{ width: "100%", margin: "10px 0" }}
                                    label="Date Of Birth"
                                    value={values.dob}
                                    onChange={(newValue) => {
                                        // Update Formik's state for the 'dob' field with the new date value
                                        setFieldValue(
                                            "dob",
                                            newValue ? newValue.toISOString() : ""
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                            {touched.dob && errors.dob && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.dob}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            sm={4}
                            md={9}
                            xs={18}
                            className={classes.gridItemPadding}
                        >
                            <SearchInput
                                options={countries}
                                label="Select Country"
                                onSelect={(field: any, value: any) =>
                                    setFieldValue(field, value)
                                }
                                displayValue="name"
                                formikValue="country"
                            />
                            {touched.country && errors.country && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.country}
                                </Typography>
                            )}
                        </Grid>


                        <Grid item sm={2} md={9}                         xs={18}>
                            <Box className={classes.buttonBox}>
                                <Button
                                    onClick={() => handleSubmit()}
                                    variant="contained"
                                    className={classes.submitBtn}
                                >
                                    Add Team Member
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Box>
    );
};

InviteTeamMembersView.defaultProps = {
    mdSize: 6,
};

const ManualRegistration = () => {
    const { classes } = useStyles();



    return (
        <>
            <Box className={classes.mainBox}>
                <InviteTeamMembersView />
            </Box>
        </>
    );
};

export default ManualRegistration;
const useStyles = makeStyles()((theme) => {
    return {
        mainBoxForm: {
            paddingTop: "20px",
        },
        buttonBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "20px 10px",
        },
        gridItemPadding: {
            padding: "5px 10px",
        },
        routeName: {},
        bottomText: {
            position: "absolute",
            bottom: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        errorMsg: {
            color: "red",
            paddingLeft: "5px",
        },
        termsText: {
            color: pallete.primaryBlack,
            textDecorationLine: "underline",
            margin: "auto 0",
        },
        checkBoxB: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        mainBox: {
            backgroundColor: "#FCFBFE",
            height: "91vh",
            padding: "30px 50px",
            overflowY: "auto",
            [theme.breakpoints.down("lg")]: {
                height: "100%",
                padding: "80px 15px",
            },
        },
        topHeadingWeb: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        bottomBTNBox: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
        },
        bottomBtns: {
            width: "100%",
            padding: "10px 20px",
            margin: "10px 5px",
            borderRadius: "2px",
            background: "#FFF",
            boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
            textTransform: "none",
        },
        iconGA: {
            height: "18px",
            width: "18px",
        },
        orText: {
            fontWeight: "500",
            lineHeight: "28px",
            color: "#36205F80",
        },
        orBox: {
            display: "flex",
            flexFlow: "row",
        },
        submitBtn: {
            ...styles.webButton,
            padding: "10px 60px",
        },
        inputBox: {
            width: "100%",
            margin: "10px 0",
        },
        rightGrid: {
            padding: "10px",
            width: "100%",
            [theme.breakpoints.down("lg")]: {
                // height: "100%",
                padding: "30px 15px",
            },
        },
        formHeading: {
            fontWeight: 700,
            lineHeight: "44.8px",
            color: pallete.primaryBlack,
            padding: "10px 0",
        },
        mainGrid: {},
        formBox: {},
        leftGrid: {
            padding: "30px 100px",
            backgroundColor: pallete.primaryBlack,
            width: "100%", // specify width
            height: "100%", // specify height
            flexShrink: 0, // apply flex-shrink
            backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
            backgroundSize: "cover", // cover the container with the background image
            backgroundPosition: "center", // center the background image
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
        },
    };
});

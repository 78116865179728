import React from "react";
import { Box, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  IntegrationCredential,
  IntegrationCredentialSkeleton,
} from "app/components/widgets/credentials";
import AddIcon from "@mui/icons-material/Add";
import { useCryptoHubCredentialStyles } from "./CryptohubStyles";
import { QMoneyhub as QMH } from "app/queries";
import { useNotification } from "app/providers";

const AddCryptoHubCredentialButton = ({ classes }: any): React.JSX.Element => {
  const createCredential = QMH.useCreateMoneyHubCredentialRequest();
  const notification = useNotification();
  const addNewCredential = () => {
    createCredential.EXECUTE({
      onSuccessFn: (data, message) => {
        notification.success("Credential Generated Successfully!");
      },
      onErrorFn: (data, message) =>
        notification.success("Failed To Generate Credential!"),
    });
  };
  return (
    <Box className={classes.credentialsCardContainer}>
      <LoadingButton
        variant="contained"
        className={classes.addButton}
        endIcon={<AddIcon />}
        onClick={addNewCredential}
        loadingPosition="end"
        loading={createCredential.isPending}
      >
        Create New Credentials
      </LoadingButton>
    </Box>
  );
};

const CryptoHubCredentialList = ({ classes }: any): React.JSX.Element[] => {
  const credentialList = QMH.useMoneyHubCredentialQuery();

  if (credentialList.isResolving) {
    return [...IntegrationCredentialSkeleton({ classes, count: 1 })];
  }

  if (credentialList.isError) {
  }

  if (credentialList.result && credentialList.result.length > 0) {
    return credentialList.result.map(
      (credential: QMH.T_IntegrationCredential, index) => {
        return (
          <Box className={classes.credentialsCardContainer}>
            <IntegrationCredential
              key={`#-integration-cred-${index}`}
              apiKey={credential.api_key}
              apiSecret={credential.secret_key}
              expiry={credential?.expiration_date?.toString() || "N/A"}
              description={credential.description || "N/A"}
              status={credential.is_active}
            />
          </Box>
        );
      }
    );
  }

  return [<></>];
};

const CryptoHubCredentialsView = (): React.JSX.Element => {
  const { classes } = useCryptoHubCredentialStyles();

  return (
    <Box>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <CryptoHubCredentialList classes={classes} />
          <AddCryptoHubCredentialButton classes={classes} />
        </Grid>

        <Grid item lg={6} xs={12}>
          <Box className={classes.credentialsColumn}>
            {/*<MoneyHubTestForm />*/}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CryptoHubCredentialsView;

import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { CustomModal } from "./CustomModal";
import {
  getAxiosAPI,
  postAxiosAPI,
  putAxiosAPI,
} from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
// import { useNavigate } from "react-router-dom";
import { redirectTo } from "../../utils";

export const UserOptions = ({ userData }: any) => {
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const [modalState, setModalState] = useState<any>({
    modalType: "",
    isOpen: false,
    text: "",
    heading: "",
    handleClick: null,
    handleClose: null,
    user: userData,
  });

  const handleClickPop = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    handleClose();
    setModalState((pre: any) => ({ ...pre, isOpen: false }));
  };

  const handleClickActions = (type: string) => {
    if (type === "verification") {
      setModalState({
        modalType: "verification",
        isOpen: true,
        text: `Are you sure you want to ${
          userData.kyc_is_verified ? "Unverify" : "Verfiy"
        } this user`,
        heading: "User verifications",
        handleClick: handleVerification,
        handleClose: handleCloseModal,
        user: userData,
      });
    } else if (type === "updateFee") {
      setModalState({
        modalType: "updateFee",
        isOpen: true,
        text: `Update user fees`,
        heading: "User Fees",
        handleClick: updateAccountFees,
        handleClose: handleCloseModal,
        user: userData,
      });
    } else if (type === "updatePassword") {
      setModalState({
        modalType: "updatePassword",
        isOpen: true,
        text: `Update user password`,
        heading: "User password",
        handleClick: updateUserPassword,
        handleClose: handleCloseModal,
        user: userData,
      });
    } else if (type === "reset2FA") {
      setModalState({
        modalType: "reset2FA",
        isOpen: true,
        text: `Are you sure you want to reset user 2FA`,
        heading: "Reset 2FA",
        handleClick: reset2fa,
        handleClose: handleCloseModal,
        user: userData,
      });
    }
  };

  const handleVerification = async () => {
    setLoading(true);
    handleCloseModal();
    console.log({ userData });
    const type = userData.kyc_is_verified ? "un-verify" : "verify";
    await getAxiosAPI(`/user-account/${userData.id}/${type}`, true)
      .then((res: any) => {
        console.log(res.data.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          setSnackbarMessage("User status updated successfully!");
          setSeverity("success");
          setShowSnackbar(true);
          window.location.reload();
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update the user action");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  const reset2fa = async () => {
    setLoading(true);
    handleCloseModal();
    console.log({ userData });
    await postAxiosAPI(
      `/verification/reset-google-2fa/${userData.id}`,
      {},
      true
    )
      .then((res: any) => {
        console.log(res.data.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          setSnackbarMessage("2FA reset successfully!");
          setSeverity("success");
          setShowSnackbar(true);
          window.location.reload();
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update the user action");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  const updateAccountFees = async (value: any) => {
    const payload = {
      trade_fees: parseFloat(value.trade_fees),
      money_hub_fees: parseFloat(value.money_hub_fees),
      crypto_hub_fees: parseFloat(value.crypto_hub_fees),
    };
    setLoading(true);
    handleCloseModal();
    await putAxiosAPI(`/user/fees/${modalState.user.id}`, payload, true)
      .then((res: any) => {
        console.log(res.data.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          setSnackbarMessage("Fees updated successfully!");
          setSeverity("success");
          setShowSnackbar(true);
          window.location.reload();
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update the fees");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  const updateUserPassword = async (value: any) => {
    const payload = {
      password: value.password,
      confirm_password: value.confirm_password,
    };
    setLoading(true);
    handleCloseModal();
    await postAxiosAPI(
      `/user-account/password/reset/${modalState.user.id}`,
      payload,
      true
    )
      .then((res: any) => {
        console.log(res.data.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        } else {
          setSnackbarMessage("Password updated successfully!");
          setSeverity("success");
          setShowSnackbar(true);
          window.location.reload();
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Failed to update the password");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  return (
    <div>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />

      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickPop}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => redirectTo({ query: { userId: userData.id } })}
        >
          User Details
        </MenuItem>
        <MenuItem onClick={() => handleClickActions("verification")}>
          {userData.kyc_is_verified ? "Unverify" : "Verfiy"} User
        </MenuItem>
        <MenuItem onClick={() => handleClickActions("updateFee")}>
          Update Fees
        </MenuItem>
        <MenuItem onClick={() => handleClickActions("updatePassword")}>
          Update Password
        </MenuItem>
        <MenuItem onClick={() => handleClickActions("reset2FA")}>
          Reset 2FA
        </MenuItem>
      </Menu>
      <CustomModal modalState={modalState} />
    </div>
  );
};

import React from "react";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import {get2FAStatusProps, getKycStatusProps} from "app/utils";
import {RQ, useAppContext} from "app/hooks";
import {ApiConfig} from "app/config";
import {useNotification} from "../../../../app/common";


type T_TeamMember = {
    last_login: string | null;
    id: string;
    email: string;
    first_name: string;
    last_name: string | null;
    mobile_no: string | null;
    dob: string;
    image: string | null;
    auth_type: number;
    auth_group_id: number;
    auth_uuid: string | null;
    kyc_auth_id: string | null;
    kyc_is_verified: boolean;
    kyc_result: string | null;
    mfa_secret: string;
    email_verified_at: string | null;
    company_id: number;
    country: string | null;
    is_2fa_active: boolean;
    trade_fees: number | null;
    money_hub_fees: number | null;
    crypto_hub_fees: number | null;
}
type T_TeamMemberList = T_TeamMember[];

export const useTeamMembersQuery = (): RQ.T_QueryResult<T_TeamMemberList> => {
    const apiConfig = ApiConfig.create("user/team-member")
    return RQ.useQueryRequest<T_TeamMemberList>({
        url: apiConfig.url,
        queryKey: apiConfig.key,
        enabled: true,
    });
}



const renderFunction = (data: T_TeamMember): React.JSX.Element[] => {
    const keyPrefix:string = data.id.toString();
    return [
        <TDF.TextContent value={data.email} key={`${keyPrefix}-EM`} />,
        <TDF.TextContent value={`${data.first_name} ${data.last_name || ''}`} key={`${keyPrefix}-FN`} />,
        <TDF.NumericContent value={`${data.mobile_no}`} key={`${keyPrefix}-PH`} />,
        <TDF.TextContent value={data.country || 'N/A'} key={`${keyPrefix}-CN`} />,

        <TDF.StatusBadge {...get2FAStatusProps(data.is_2fa_active)} key={`${keyPrefix}-2FA`} />,
        <TDF.StatusBadge {...getKycStatusProps(data)} key={`${keyPrefix}-KYC`} />,


        <TDF.DateTimeContent dateTime={`${data.dob}`} key={`${keyPrefix}-DOB`}  format={'Do MMMM YYYY'}/>,
        <TDF.DateTimeContent dateTime={data.email_verified_at} key={`${keyPrefix}-DT`} />,
        <TDF.DateTimeContent dateTime={data.last_login} key={`${keyPrefix}-DTLL`} />,
    ]
}

const teamMembersDataMap = {
    "Email": "email",
    "Name": "first_name",
    "Mobile": "mobile",
    "Country": "country",
    "2FA": "is_2fa_active",
    "KYC": "kyc_is_verified",
    "Date Of Birth": "dob",
    "Date": "email_verified_at",
    "Last Login": "last_login",
};



type T_TeamMembersViewProps = {};

export const TeamMembersView = (props: T_TeamMembersViewProps): React.JSX.Element => {
    const {user} = useAppContext();


    const notification = useNotification()
    const teamMembersQuery=useTeamMembersQuery();
    const headings: string[] = Object.keys(teamMembersDataMap);
    const teamMembers = teamMembersQuery.isResolved ? teamMembersQuery.result:[];

    const getMenuItemOptionsFn = async (data: T_TeamMember): Promise<TDF.T_MenuItemOptionList> => {
        const msg :string = "You dont have permission to perform this action."
        return new Promise((resolve, reject) => {
            resolve([
                {name: "Remove Team Member", onClickHandler: ()=>notification.info(msg)},
                {name: "Edit Team Member", onClickHandler: ()=>notification.info(msg)},
            ]);
        });
    }

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Team Members",
        headings: headings,
        isLoading: teamMembersQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: teamMembers,
        actionColumn: !user.isTeamMember(),
        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['email', 'full_name', 'mobile'],
        },
    }
    if(!user.isTeamMember()){
        tableComponentProps.downloadOptions = {
            fileName: "team-members.csv",
            dataMap: teamMembersDataMap,
        }
        tableComponentProps.actionOptions = {getMenuItemOptionsFn}
    }
    return (<DefaultTableComponent {...tableComponentProps} />)

}
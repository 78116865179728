import React from "react";
import * as RHF from "react-hook-form";
import {TextField, TextFieldProps} from "@mui/material";
import {T_FormInstance, T_UseFormResult} from "./useForm";
import {LoaderButton, T_LoaderBtnProps} from "../buttons";
import {useInputFieldStyles} from "../fields/InputFieldStyles";


/**
 * Type definition for form input options.
 * Extends TextFieldProps and includes optional inputElement property.
 */
type T_FormInputOptions = TextFieldProps & {
    inputElement?: React.ElementType;
};

/**
 * Type definition for FormInput component props.
 * Includes name, label, formInstance, optional inputOptions, and other dynamic props.
 */
type T_FormInputProps = Partial<RHF.ControllerProps> & {
    name: string;
    label: string;
    formInstance: T_FormInstance;
    inputOptions?: T_FormInputOptions;
    [key: string]: any;
}

/**
 * Type definition for render function props used in FormInput component.
 * Includes field, fieldState, and formState properties.
 */
type T_RenderFunctionProps = {
    field: RHF.ControllerRenderProps;
    fieldState: RHF.ControllerFieldState;
    formState: RHF.UseFormStateReturn<any>;
}

/**
 * FormInput component.
 * Renders a form input field managed by React Hook Form.
 * @param {T_FormInputProps} param0 - Destructured props: name, label, formInstance, inputOptions, and other props.
 * @returns {React.JSX.Element} JSX element for the input field.
 */
export const FormInput = ({name, label, formInstance, inputOptions, ...props}: T_FormInputProps): React.JSX.Element => {
    const {classes} = useInputFieldStyles();

    // Destructure necessary properties from formInstance.
    const {formState, control, ...rest}: RHF.UseFormReturn = formInstance;
    // Destructure input element and input field props from inputOptions.
    const {inputElement: Component, ...inputFieldProps}: T_FormInputOptions = (inputOptions || {});

    const key = `${formInstance.id}-input-${name}`;
    /**
     * Render function for the input field.
     * Provides controlled field properties and handles validation and error display.
     * @param {T_RenderFunctionProps} param0 - Destructured props: field, fieldState, formState.
     * @returns {React.JSX.Element} JSX element for the input component.
     */
    const renderFunction = ({field, fieldState, formState}: T_RenderFunctionProps): React.JSX.Element => {
        // Determine the component to use for the input field (default to TextField).
        const InputComponent: React.ElementType = Component || TextField;

        // Prepare props for the TextField component, including error handling and event management.
        const textFieldProps: TextFieldProps = {
            error: (formState.errors[name] && Boolean(formState.errors[name])),
            helperText: formState.errors[name]?.["message"]?.toString(),
            label, ...inputFieldProps, ...{
                ...field,
                onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
                    inputFieldProps?.onChange && inputFieldProps.onChange(e);
                    field.onChange(e);
                },
                onBlur: (e: React.FocusEvent<HTMLInputElement>): void => {
                    inputFieldProps?.onBlur && inputFieldProps.onBlur(e);
                    field.onBlur();
                },

            }
        };

        // Render the input component with the prepared props.
        return (<InputComponent {...textFieldProps} sx={{margin: "10px"}} key={key}/>);
    };

    // Return the Controller component from React Hook Form, which wraps the input field.
    return (<RHF.Controller {...{...(props || {}), name, control, render: renderFunction}} />);
}

/**
 * Type definition for FormSubmitButton component props.
 * Extends T_LoaderBtnProps and includes formInstance property.
 */
export type T_FormSubmitButtonProps = T_LoaderBtnProps & {
    formInstance: T_FormInstance;
};

/**
 * FormSubmitButton component.
 * Renders a submit button that integrates with form state and displays a loader during form submission.
 * @param {T_FormSubmitButtonProps} param0 - Destructured props: formInstance and other LoaderButton options.
 * @returns {React.JSX.Element} JSX element for the submit button.
 */
export const FormSubmitButton = ({formInstance, ...opt}: T_FormSubmitButtonProps): React.JSX.Element => {
    return LoaderButton({
        loading: formInstance.isLoading || opt?.loading,
        ...(opt || {}), type: "submit",
    });
};


import {useEffect} from "react";
import * as ReactQuery from "@tanstack/react-query";
import {ApiRequest, ApiResponse, T_ArrayOrObject} from "app/utils/http";
import {T_SearchParamsOptions, T_UseSearchParametersResult, useSearchQueryParameters} from "./QueryPayloads";

/**
 * T_UseQueryOptions extends React Query's options and adds custom fields for API requests.
 * This type is used to define the structure of options for query requests.
 */
type T_UseQueryOptions = ReactQuery.UndefinedInitialDataOptions & {
    url: string;  // The API endpoint URL
    method?: string;  // The HTTP method to use (GET, POST, etc.)
    params?: T_ArrayOrObject;  // Query parameters or path parameters
}

/**
 * T_UseQueryParams extends T_UseQueryOptions to include a payload field.
 * This type is used for query requests that may include a payload (e.g., POST requests).
 */
export type T_UseQueryParams = T_UseQueryOptions & {
    payload?: T_ArrayOrObject;  // The request body for methods like POST, PUT, etc.
};

/**
 * T_QueryResultData is a placeholder type for the structure of query result data.
 * It can be extended or modified based on the specific needs of the application.
 * Currently, it's an empty object, allowing for flexible data structures.
 */
export type T_QueryResultData<Tdata = any> = {}

/**
 * T_QueryResult extends React Query's UseQueryResult and adds custom fields.
 * This type provides a standardized structure for query results in the application.
 */
export type T_QueryResult<Tdata = any> = ReactQuery.UseQueryResult & {
    data: ApiResponse<Tdata> | any;  // The response data, wrapped in an ApiResponse or any other type
    isResolved: boolean;  // Indicates if the query has completed successfully
    isResolving: boolean;  // Indicates if the query is in progress
    client: ReactQuery.QueryClient;  // The React Query client instance
    getResult: (options ?: ReactQuery.RefetchOptions) => Promise<Tdata>;  // A function to manually refetch the query
    result: Tdata;  // The actual data result, extracted from the ApiResponse
}



/**
 * useQueryRequest is a custom hook that wraps React Query's useQuery hook.
 * It provides a standardized way to make API requests and handle their results.
 */
function useQueryRequest<Tdata = any>({url, params, payload, ...options}: T_UseQueryParams): T_QueryResult<Tdata> {

    let result: Tdata | any;
    params = params || []
    const method: string = options.method || "GET";

    /**
     * Get the query client from React Query.
     * This is used to interact with the query cache.
     */
    const client = ReactQuery.useQueryClient();

    /**
     * Define the query function that will be used to fetch data.
     * This function uses the ApiRequest utility to make the actual API call.
     */
    const queryFunction = async (): Promise<ApiResponse<Tdata>> => {
        return ApiRequest.Request<Tdata>({url, params, method, payload,}).apiResponse();
    }

    /**
     * Define a select function to process the API response.
     * This allows for custom data transformation if a select option is provided.
     */
    const selectFunction = (response: ApiResponse<Tdata>): ApiResponse<Tdata> => {
        result = ((options.select && options.select(response))) || response.getData() as Tdata;
        return response;
    }

    /**
     * Prepare the options for React Query's useQuery hook.
     * This includes merging provided options with defaults and setting up the query key and function.
     */
    const queryOptions: ReactQuery.UndefinedInitialDataOptions = {
        ...options,
        queryKey: [...options.queryKey, ...(Array.isArray(params) ? params : [params])],
        queryFn: options?.queryFn || queryFunction,
        placeholderData: {},
        select: (result: any) => selectFunction(result as ApiResponse<Tdata>),
    }

    /**
     * Use React Query's useQuery hook to perform the query.
     */
    const queryRequest = ReactQuery.useQuery(queryOptions, client);

    /**
     * Determine if the query is currently resolving (in progress).
     */
    const isResolving: boolean = (queryRequest.isPending || queryRequest.isRefetching || queryRequest.isLoading);

    /**
     * Determine if the query has resolved successfully.
     */
    const isResolved: boolean = (!isResolving && queryRequest.data !== undefined);

    /**
     * Provide a method to manually refetch the query and get the result.
     */
    const getResult = async (options ?: ReactQuery.RefetchOptions): Promise<Tdata> => {
        const response: ReactQuery.QueryObserverResult<ApiResponse<Tdata> | any> = await queryRequest.refetch(options);
        return response.data.getData() as Tdata;
    }

    /**
     * Return the query result along with additional utility properties and methods.
     */
    return {...queryRequest, client, isResolved, isResolving, getResult, result};
}

/**
 * usePostQueryRequest is a convenience wrapper around useQueryRequest for POST requests.
 * It sets the method to "POST" by default, simplifying the API for POST operations.
 */
function usePostQuery<Tdata = any>({url, params, payload, ...options}: T_UseQueryParams): T_QueryResult<Tdata> {
    return useQueryRequest<Tdata>({url, params, payload, method: "POST", ...options});
}


/**
 * T_UseSearchQueryParams defines the structure for search query parameters.
 * It includes options specific to search functionality.
 */
export type T_UseSearchQueryParams = {
    searchParams?: Partial<T_SearchParamsOptions>;  // Optional search parameters
    refetchOnChange?: boolean;  // Whether to refetch data when search params change
    strict?: boolean;  // Whether to enforce strict search behavior
}

/**
 * T_UseSearchQueryOptions combines T_UseQueryOptions with T_UseSearchQueryParams.
 * This type is used for search query requests, which may have additional options
 * beyond standard query options.
 */
type T_UseSearchQueryOptions = T_UseQueryOptions & T_UseSearchQueryParams;

/**
 * T_SearchQueryResult defines the structure of a search query result.
 * It includes a count of total results and an array of records.
 * This structure is common in paginated or search-based API responses.
 */
export type T_SearchQueryResult<Tdata = any> = {
    count: number;  // Total number of results
    records: Tdata[];  // Array of result records
};

/**
 * T_UseSearchQueryResult combines the search parameters and the query result.
 * It returns a tuple with search parameters and the query result.
 * This type provides a standardized structure for search query results in the application.
 */
export type T_UseSearchQueryResult<Tdata = any> = [T_UseSearchParametersResult, T_QueryResult<T_SearchQueryResult<Tdata>>];


/**
 * useSearchQuery is a custom hook for performing search queries.
 * It combines search parameter handling with the query request process.
 */
function useSearchQuery<Tdata = any>(searchQueryOptions: T_UseSearchQueryOptions): T_UseSearchQueryResult<Tdata> {

    /**
     * Destructure the options from the provided searchQueryOptions.
     * This separates search-specific options from general query options.
     */
    const {searchParams, refetchOnChange, url, params, strict, ...options} = searchQueryOptions;

    /**
     * Use the useSearchQueryParameters hook to handle search parameters.
     * This hook likely manages the state and processing of search parameters.
     */
    const searchQueryParams: T_UseSearchParametersResult = useSearchQueryParameters(searchParams || {});

    /**
     * If 'strict' is enabled, ensure the URL ends with '/search'.
     * This allows for a standardized API endpoint structure for search queries.
     */
    const requestUrlPostfix: string = (strict && !url.endsWith('/search')) ? '/search' : '';

    /**
     * Execute the search query request using the usePostQueryRequest hook.
     * This sets up the actual API request with the necessary parameters and options.
     */
    const searchQuery = usePostQuery<T_SearchQueryResult<Tdata>>({
        ...(options || {}),
        payload: searchQueryParams.values,
        url: `${url}${requestUrlPostfix}`,
        enabled: (options?.enabled === true || searchParams !== undefined),
        queryKey: ["search", ...(options.queryKey || []), searchQueryParams.values],
    });

    /**
     * Set up an effect to refetch data when search parameters change, if enabled.
     * This ensures that the search results stay up-to-date with the current search parameters.
     */
    useEffect(() => {
        if (refetchOnChange === true) {
            searchQuery.refetch({searchQueryParams} as any);
        }
        return () => {
        } // Cleanup function (empty in this case)
    }, [searchQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Return both the search parameters and the query result.
     * This allows the consumer to access both the current search state and its results.
     */
    return [searchQueryParams, searchQuery];
}


export {useQueryRequest, ApiRequest, useSearchQuery,usePostQuery};

import React from "react";
import {Box, TableCell, TableCellProps, TableRow, TableRowProps, Typography,} from "@mui/material";
import {styled} from "@mui/material/styles";
import {pallete} from "app/styles";
import {T_PaginationState} from "./TableBodyFragments";
import {TableHeadingSkeleton} from "./TableSkeletons";


const DefaultStyledTableCell = styled(TableCell)(({theme}) => ({
    // Example custom styles
    fontSize: "14px",
    color: pallete.primaryBlack,

    fontFamily: "Montserrat",
    padding: theme.spacing(1.5),
    // borderBottom: "none",
}));

const DefaultStyledTableRow = styled(TableRow)(({theme}) => ({
    // padding: "20px 0 !important",
    height: "1rem",

    // Hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&:hover": {
        backgroundColor: `${theme.palette.action.selected}!important`, // Use !important to override inline styles if necessary
    },
}));

export type T_CommonTableComponentProps = {
    classes: any;
    isLoading: boolean | undefined;
    keyPrefix: string;
    hasAction?: boolean,
    columnCount?: number;
    paginationState?: T_PaginationState;
}

export type T_TableRowAndCellDataCommonProps = {
    keyPrefix: string | number;
}

type T_StyledTableRowProps = TableRowProps & T_TableRowAndCellDataCommonProps;

export const StyledTableRow = ({keyPrefix, ...props}: T_StyledTableRowProps): React.JSX.Element => {
    return (
        <DefaultStyledTableRow key={`${keyPrefix}-Table-ROW`} {...(props || {})} sx={{maxHeight: "50px !important"}}>
            {props.children}
        </DefaultStyledTableRow>
    );
}

type T_StyledTableCellProps = TableCellProps & T_TableRowAndCellDataCommonProps;

export const StyledTableCell = ({keyPrefix, ...props}: T_StyledTableCellProps): React.JSX.Element => {
    return (
        <DefaultStyledTableCell key={`${keyPrefix}-Table-ROW-CELL`} {...(props || {})}
                                sx={{maxHeight: "50px !important"}}>
            {props.children}
        </DefaultStyledTableCell>
    )
}

type T_TableColumnHeaderProps = T_TableRowAndCellDataCommonProps & {
    isLoading: boolean | undefined;
    heading: string;
}
export const TableColumnHeader = ({keyPrefix, isLoading, heading}: T_TableColumnHeaderProps): React.JSX.Element => {

    if (isLoading) {
        return TableHeadingSkeleton(keyPrefix.toString());
    }
    return (
        <Typography key={`${keyPrefix}-TH-VAL`} variant="h6" component={'span'} color="primary">
            {heading}
        </Typography>
    )
}

export const TableNoRecordsFound = ({colSpan}: { colSpan: number }): React.JSX.Element => {
    return (
        <DefaultStyledTableRow>
            <DefaultStyledTableCell component="th" scope="row" colSpan={colSpan}>
                <Box sx={{height: "100px", alignItems: "center", width: "100%", padding: "20px"}}>
                    <Typography align="center" variant="h5" color="primary">
                        No Record Found
                    </Typography>
                </Box>
            </DefaultStyledTableCell>
        </DefaultStyledTableRow>
    )
}

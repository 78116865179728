import React from "react";
import {QMoneyhubTransaction as QMHT} from "app/queries";
import {DefaultTableComponent, T_DefaultTableComponentProps, TDF} from "app/components/table";
import {getStatusBadgePropsForTradeOrder} from "app/utils";


const renderFunction = (data: QMHT.T_MoneyHubTransaction): React.JSX.Element[] => {
    const keyPrefix: string = `moneyhub_transaction_${data.id}`;
    const statusProps = getStatusBadgePropsForTradeOrder(
        data.status_id.toString(), data.status_name
    );
    return [
        <TDF.TextContent value={data.sender_email} key={`${keyPrefix}-MNYUSR`} />,
        <TDF.TextContent value={`${data.receiver_email}`} key={`${keyPrefix}-MRCHNT`} />,
        <TDF.NumericContent value={`${data.amount}`} key={`${keyPrefix}-AMT`} />,
        <TDF.TextContent value={`${data.currency_symbol}`} key={`${keyPrefix}-CRR`} />,
        <TDF.NumericContent value={`${data.fees}`} key={`${keyPrefix}-ORDF`} />,
        <TDF.NumericContent value={`${data.asset_name}`} key={`${keyPrefix}-ASTN`} />,
        <TDF.TextContent value={`${data.asset_quantity}`} key={`${keyPrefix}-ASTQ`} />,
        <TDF.StatusBadge {...statusProps} key={`${keyPrefix}-STA`} />,
        <TDF.DateTimeContent dateTime={(data?.created_at?.toString() || '')} key={`${keyPrefix}-ORD`} />,
    ]
}

const moneyhubUserOrdersDataMap = {
    "User": "sender_email",
    "Merchant": "receiver_email",
    "Amount": "amount",
    "Currency": "currency_symbol",
    "Fees": "fees",
    "Asset": "asset_name",
    "Quantity": "asset_quantity",
    "Status": "status_name",
    "Date": "created_at",
};

type T_MoneyHubUserOrdersProps = {
    userId: string;
    setMoneyHubIdFn: (state: any) => void;
}
export const MoneyHubUserOrders = ({userId, setMoneyHubIdFn}: T_MoneyHubUserOrdersProps): React.JSX.Element => {

    const [moneyhubSearchParams, moneyhubOrdersQuery] = QMHT.useMoneyhubOrdersSearchQuery({
        searchParams: {filters: {sender_id: [userId]}}
    });

    const headings: string[] = Object.keys(moneyhubUserOrdersDataMap);
    const tableData = moneyhubOrdersQuery.isResolved ? moneyhubOrdersQuery.result.records : [];

    const getMenuItemOptionsFn = async (data: QMHT.T_MoneyHubTransaction): Promise<TDF.T_MenuItemOptionList> => {
        return new Promise((resolve, reject) => {
            resolve([
                {name: "All User Orders", onClickHandler: () => setMoneyHubIdFn({userId: data.sender_id})},
                {name: "All Merchant Orders", onClickHandler: () => setMoneyHubIdFn({merchantId: data.receiver_id})},
            ])
        });
    }

    const tableComponentProps: T_DefaultTableComponentProps = {
        title: "Moneyhub User Orders",
        headings: headings,
        isLoading: moneyhubOrdersQuery.isResolving,
        initialRowsPerPage: 10,
        tableData: tableData,
        actionColumn: true,

        renderFunction: renderFunction,
        searchOptions: {
            searchKeys: ['sender_email', 'full_name', 'mobile',],
        },
        downloadOptions: {
            fileName: "moneyhub-user-orders.csv",
            dataMap: moneyhubUserOrdersDataMap,
        },
        // actionOptions: {getMenuItemOptionsFn}
        tableBreadcrumbsOptions: {
            breadcrumbs:[
                {name: "Moneyhub Orders", onClickHandler: () => setMoneyHubIdFn({})},
                {name: "User Orders", onClickHandler: () => setMoneyHubIdFn({userId})},
            ]
        },

    }

    return (<DefaultTableComponent {...tableComponentProps} />)
}

import React from "react";
import ComingSoonPage from "app/components/unsorted/CommingSoon";
import {TabContent} from "app/components/tabs";
import SecurityTab from "./SecurityTab";
import VerificationTab from "./VerificationTab";

const Security = () => {
    const tabHeading: string = "Security";
    const tabData = [
        {label: "Verification", component: <SecurityTab />},
        {label: "Security", component: <SecurityTab />},
    ];
    return TabContent({tabData,tabHeading});

};

export default Security;

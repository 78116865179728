import React from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { pallete } from "app/styles";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMoneyHubStyles } from "./Styles";
import {
  StatsKey,
  T_MoneyhubTransactionStats,
  useMoneyHubDayBasedTransactionStatsRequest,
  useMoneyHubTransactionStatsRequest,
  useMoneyHubTransactionTableRequest,
} from "./MoneyHubDashboardQueries";
import { StatsCard } from "app/components/widgets";
import Loader from "app/components/unsorted/Loader";
import {
  AccountBalance,
  AttachMoney,
  Done,
  LocalShipping,
  Pending,
} from "@mui/icons-material";
import MoneyHubTransactionTable from "./MoneyHubTransactionTable";

export const weeklyTotalIN = [
  {
    t1Data: "Monday",
    dayShortName: "Mon",
    t2Data: "30.10.2024",
    price: 40000,
  },
  {
    t1Data: "Tuesday",
    dayShortName: "Tue",
    t2Data: "30.10.2024",
    price: 39000,
  },
  {
    t1Data: "Wednesday",
    dayShortName: "Wed",
    t2Data: "30.10.2024",
    price: 28000,
  },
  {
    t1Data: "Thursday",
    dayShortName: "Thu",
    t2Data: "30.10.2024",
    price: 25000,
  },
  {
    t1Data: "Friday",
    dayShortName: "Fri",
    t2Data: "30.10.2024",
    price: 55000,
  },
  {
    t1Data: "Saturday",
    dayShortName: "Sat",
    t2Data: "30.10.2024",
    price: 48000,
  },
  {
    t1Data: "Sunday",
    dayShortName: "Sun",
    t2Data: "30.10.2024",
    price: 45000,
  },
];

const totalMonthely = [
  { name: "Jan", t1Data: "January", t2Data: "2024", price: 30000 },
  { name: "Feb", t1Data: "February", t2Data: "2024", price: 29000 },
  { name: "Mar", t1Data: "March", t2Data: "2024", price: 28000 },
  { name: "Apr", t1Data: "April", t2Data: "2024", price: 60000 },
  { name: "May", t1Data: "May", t2Data: "2024", price: 55000 },
  { name: "Jun", t1Data: "June", t2Data: "2024", price: 28000 },
  { name: "Jul", t1Data: "July", t2Data: "2024", price: 45000 },
  { name: "Aug", t1Data: "August", t2Data: "2024", price: 28000 },
  { name: "Sep", t1Data: "September", t2Data: "2024", price: 56000 },
  { name: "Oct", t1Data: "October", t2Data: "2024", price: 28000 },
  { name: "Nov", t1Data: "November", t2Data: "2024", price: 25000 },
  { name: "Dec", t1Data: "December", t2Data: "2024", price: 65000 },
];

const conversionRate = [
  {
    t1Data: "Monday",
    dayShortName: "Mon",
    t2Data: "30.10.2024",
    t3: 52021,
    t4: 15000,
    t5: 5000,
    price: 40000,
  },
  {
    t1Data: "Tuesday",
    dayShortName: "Tue",
    t2Data: "30.10.2024",
    t3: 21912,
    t4: 30000,
    t5: 8000,
    price: 39000,
  },
  {
    t1Data: "Wednesday",
    dayShortName: "Wed",
    t2Data: "30.10.2024",
    t3: 26063,
    t4: 65000,
    t5: 6000,
    price: 28000,
  },
  {
    t1Data: "Thursday",
    dayShortName: "Thu",
    t2Data: "30.10.2024",
    t3: 58000,
    t4: 36000,
    t5: 9000,
    price: 25000,
  },
  {
    t1Data: "Friday",
    dayShortName: "Fri",
    t2Data: "30.10.2024",
    t3: 53000,
    t4: 47000,
    t5: 50000,
    price: 55000,
  },
  {
    t1Data: "Saturday",
    dayShortName: "Sat",
    t2Data: "30.10.2024",
    t3: 52000,
    t4: 34000,
    t5: 55000,
    price: 48000,
  },
  {
    t1Data: "Sunday",
    dayShortName: "Sun",
    t2Data: "30.10.2024",
    t3: 25000,
    t4: 57000,
    t5: 3000,
    price: 45000,
  },
];

export const PrimaryToolTip = ({ active, payload, label, classes }: any) => {
  if (active && payload && payload.length) {
    console.log(payload);
    return (
      <Card className={classes.cardTooltip}>
        <Box className={classes.tooltipBox}>
          <Typography
            sx={{ fontWeight: 600, paddingRight: "20px" }}
            variant="body1"
          >
            {payload[0].payload.t1Data}
          </Typography>
          <Typography variant="body1">{payload[0].payload.t2Data}</Typography>
        </Box>
        <Typography variant="h6" sx={{ paddingTop: "10px" }}>
          $ {payload[0].value.toLocaleString()}
        </Typography>
      </Card>
    );
  }

  return null;
};

const SecondaryToolTip = ({ active, payload, label, classes }: any) => {
  if (active && payload && payload.length) {
    console.log(payload);
    return (
      <Card className={classes.cardTooltip}>
        <Box className={classes.tooltipBox}>
          <Typography
            sx={{ fontWeight: 600, paddingRight: "20px" }}
            variant="body1"
          >
            {payload[0].payload.t1Data}
          </Typography>
          <Typography variant="body1">{payload[0].payload.t2Data}</Typography>
        </Box>
        <Box className={classes.tooltipBox}>
          <Typography
            sx={{ fontWeight: 600, paddingRight: "20px" }}
            variant="body1"
          >
            Total Access
          </Typography>

          <Typography variant="body1">{payload[0].payload.t3}</Typography>
        </Box>
        <Box className={classes.tooltipBox}>
          <Typography
            sx={{ fontWeight: 600, paddingRight: "20px" }}
            variant="body1"
          >
            Complete
          </Typography>
          <Typography variant="body1">{payload[0].payload.t4}</Typography>
        </Box>
        <Box className={classes.tooltipBox}>
          <Typography
            sx={{ fontWeight: 600, paddingRight: "20px" }}
            variant="body1"
          >
            Incompelete
          </Typography>
          <Typography variant="body1">{payload[0].payload.t5}</Typography>
        </Box>
      </Card>
    );
  }

  return null;
};

function getDayFromDate(dateString: string) {
  const date = new Date(dateString);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysOfWeek[date.getDay()];
}

const getDaysStatData = (daysStats: any) => {
  return Object.keys(daysStats).map((key) => {
    return {
      day: key,
      dayShort: getDayFromDate(key).slice(0, 3),
      ...daysStats[key],
    };
  });
};

const DaysStatToolTipBox = ({ title, value }: any) => {
  const { classes } = useMoneyHubStyles();
  return (
    <Box className={classes.tooltipBox}>
      <Typography
        sx={{ fontWeight: 600, paddingRight: "20px" }}
        variant="body1"
      >
        {title}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

const DaysStatToolTip = ({ active, payload, label }: any) => {
  const { classes } = useMoneyHubStyles();
  if (active && payload && payload.length) {
    return (
      <Card className={classes.cardTooltip}>
        {StatsKey.map((key, index) => {
          return (
            <DaysStatToolTipBox
              key={index}
              title={key}
              value={payload[0].payload[key]}
            />
          );
        })}
      </Card>
    );
  }

  return null;
};

export const MoneyHubDashboardView = () => {
  const { classes } = useMoneyHubStyles();
  const stats = useMoneyHubTransactionStatsRequest();
  const daysStat = useMoneyHubDayBasedTransactionStatsRequest(7);

  const daysStatData = getDaysStatData(daysStat.result || {});
  const [visibleStats, setVisibleStats] = React.useState(StatsKey);

  const StatsCardData = {
    Total: {
      title: "Total",
      icon: <AttachMoney className={classes.icon} />,
      color: "#7F56D9",
    },
    "Payment Pending": {
      title: "Payment Pending",
      icon: <Pending className={classes.icon} />,
      color: "#FF9800",
    },
    "Payment Received": {
      title: "Payment Received",
      icon: <Done className={classes.icon} />,
      color: "#4CAF50",
    },
    "Sent To Merchant": {
      title: "Sent To Merchant",
      icon: <LocalShipping className={classes.icon} />,
      color: "#2196F3",
    },
    "Settled With Merchant": {
      title: "Settled With Merchant",
      icon: <AccountBalance className={classes.icon} />,
      color: "#673AB7",
    },
  };

  if (stats.isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <Box className={classes.mainBox}>
      <Grid container justifyContent={"space-between"} padding={"10px"}>
        <Grid
          className={classes.StatsCardContainer}
          container
          spacing={1}
          justifyContent="space-between"
        >
          {StatsKey.map((key, index) => (
            <Grid item xs={12} sm={6} md={2.3}>
              <StatsCard
                key={index}
                title={StatsCardData[key].title}
                value={stats.result?.[key] || 0}
                color={StatsCardData[key].color}
                icon={StatsCardData[key].icon}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item md={12} className={classes.gridItems}>
          <Card className={classes.commonCards}>
            <Box className={classes.commonCardsHBox}>
              <Typography variant="h6" color="primary">
                Weekly total IN by day
              </Typography>
              <Box>
                {/* <Button
                                    endIcon={
                                        <Box
                                            component="img"
                                            // className={classes.endIconBox}
                                            src="assets/icons/downArrow.svg"
                                        />
                                    }
                                    startIcon={
                                        <Box
                                            component="img"
                                            className={classes.startIconBox}
                                            src="assets/icons/calendar.svg"
                                        />
                                    }
                                    className={classes.iconsBtn}
                                >
                                    Last Week
                                </Button> */}
              </Box>
            </Box>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={daysStatData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dayShort" />
                <YAxis />
                <Tooltip content={<DaysStatToolTip />} />
                <Legend
                  onClick={(data) => {
                    const key =
                      data.dataKey as keyof T_MoneyhubTransactionStats;
                    if (visibleStats.includes(key)) {
                      setVisibleStats(
                        visibleStats.filter((key) => key !== data.dataKey)
                      );
                    } else {
                      setVisibleStats([...visibleStats, key]);
                    }
                  }}
                />
                {StatsKey.map((key, index) => (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={key}
                    stroke={StatsCardData[key].color}
                    activeDot={{ r: 8 }}
                    hide={!visibleStats.includes(key)}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>{" "}
          </Card>
        </Grid>
        {/* <Grid item lg={6} xs={12} className={classes.gridItems}>
                <Card className={classes.commonCards}>
                <Box className={classes.commonCardsHBox}>
                        <Typography variant="h6" color="primary">
                            Weekly total IN by day
                        </Typography>
                        <Box>
                            <Button
                                endIcon={
                                    <Box
                                        component="img"
                                        // className={classes.endIconBox}
                                        src="assets/icons/downArrow.svg"
                                    />
                                }
                                startIcon={
                                    <Box
                                    component="img"
                                    className={classes.startIconBox}
                                    src="assets/icons/calendar.svg"
                                    />
                                }
                                className={classes.iconsBtn}
                                >
                                Last Week
                                </Button>
                                </Box>
                    </Box>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={weeklyTotalIN}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dayShortName" />
                            <YAxis />
                            <Tooltip content={<PrimaryToolTip classes={classes} />} />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="price"
                                stroke="#D555F0"
                                activeDot={{r: 8}}
                            />
                        </LineChart>
                    </ResponsiveContainer>{" "}
                    </Card>
            </Grid> */}

        {/* 2 */}

        {/* <Grid item lg={6} xs={12} className={classes.gridItems}>
                <Card className={classes.commonCards}>
                    <Box className={classes.commonCardsHBox}>
                        <Typography variant="h6" color="primary">
                        Total monthly
                        </Typography>
                        <Box>
                            <Button
                                endIcon={
                                    <Box
                                        component="img"
                                        // className={classes.endIconBox}
                                        src="assets/icons/downArrow.svg"
                                        />
                                        }
                                startIcon={
                                    <Box
                                    component="img"
                                    className={classes.startIconBox}
                                    src="assets/icons/calendar.svg"
                                    />
                                    }
                                    className={classes.iconsBtn}
                                    >
                                Last Year
                                </Button>
                                </Box>
                                </Box>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                        data={totalMonthely}
                        margin={{
                            top: 5,
                            right: 30,
                                left: 20,
                                bottom: 5,
                                }}
                                >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<PrimaryToolTip classes={classes} />} />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="price"
                                stroke={pallete.selections.brown}
                                activeDot={{r: 8}}
                                />
                                </LineChart>
                    </ResponsiveContainer>{" "}
                </Card>
                </Grid> */}

        {/* 3 */}

        {/* <Grid item lg={6} xs={12} className={classes.gridItems}>
                <Card className={classes.commonCards}>
                <Box className={classes.commonCardsHBox}>
                <Typography variant="h6" color="primary">
                            Conversion Rate
                            </Typography>
                            <Box>
                            <Button
                            endIcon={
                                <Box
                                component="img"
                                // className={classes.endIconBox}
                                src="assets/icons/downArrow.svg"
                                />
                                }
                                startIcon={
                                    <Box
                                        component="img"
                                        className={classes.startIconBox}
                                        src="assets/icons/calendar.svg"
                                        />
                                        }
                                        className={classes.iconsBtn}
                                        >
                                        Last Week
                                        </Button>
                                        </Box>
                                        </Box>
                                        <ResponsiveContainer width="100%" height={300}>
                                        <LineChart
                                        data={conversionRate}
                                        margin={{
                                            top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dayShortName" />
                            <YAxis />
                            <Tooltip content={<SecondaryToolTip classes={classes} />} />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="price"
                                stroke={pallete.selections.blue}
                                activeDot={{r: 8}}
                                />
                                <Line
                                type="monotone"
                                dataKey="t3"
                                stroke={pallete.selections.green}
                                activeDot={{r: 8}}
                                />
                                <Line
                                type="monotone"
                                dataKey="t4"
                                stroke={pallete.selections.red}
                                activeDot={{r: 8}}
                            />
                        </LineChart>
                        </ResponsiveContainer>{" "}
                        </Card>
            </Grid> */}

        {/* 4 */}

        {/* <Grid item lg={6} xs={12} className={classes.gridItems}>
                <Card className={classes.commonCards}>
                    <Box className={classes.commonCardsHBox}>
                    <Typography variant="h6" color="primary">
                    Active Users
                    </Typography>
                    <Box>
                            <Button
                                endIcon={
                                    <Box
                                        component="img"
                                        // className={classes.endIconBox}
                                        src="assets/icons/downArrow.svg"
                                    />
                                }
                                startIcon={
                                    <Box
                                        component="img"
                                        className={classes.startIconBox}
                                        src="assets/icons/calendar.svg"
                                        />
                                }
                                className={classes.iconsBtn}
                            >
                            Last Week
                            </Button>
                            </Box>
                            </Box>
                            <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                            data={conversionRate}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                                }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="dayShortName" />
                        <YAxis />
                        <Tooltip content={<SecondaryToolTip classes={classes} />} />
                        <Legend />
                        <Line
                                type="monotone"
                                dataKey="price"
                                stroke={pallete.selections.blue}
                                activeDot={{r: 8}}
                            />
                            <Line
                            type="monotone"
                                dataKey="t3"
                                stroke={pallete.selections.green}
                                activeDot={{r: 8}}
                            />
                            <Line
                                type="monotone"
                                dataKey="t4"
                                stroke={pallete.selections.red}
                                activeDot={{r: 8}}
                                />
                                </LineChart>
                                </ResponsiveContainer>{" "}
                                </Card>
                                </Grid> */}
        <Grid item md={12} className={classes.gridItems}>
          <MoneyHubTransactionTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MoneyHubDashboardView;

import React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {pallete} from "app/styles";
import {useNavigate} from "react-router-dom";
import {useStyles} from "../Style";
import { motion } from "framer-motion";

const Platform = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.platformBox} >
        <Typography color={pallete.primaryWhite} variant="h1" align="center">
          Our Platform
        </Typography>
        <Grid container className={classes.securityGrid}>
          <Grid item lg={6} className={classes.securityLeftGrid}>
            <Typography
              color={pallete.primaryWhite}
              variant="h5"
              sx={{ fontWeight: 600, padding: "20px 0" }}
            >
              Convenient Structure{" "}
            </Typography>
            <Typography color={pallete.primaryWhite} variant="body2">
              Trading cryptocurrencies and execution management. System
              connected to all major exchanges brokers and market makers
              globally. We offer best execution and seamless settlement in all
              cryptocurrencies.
            </Typography>
            <Button
              sx={{ marginTop: "50px" }}
              className={classes.btn0}
              onClick={() => navigate("/signup")}
            >
              Get Started
            </Button>
          </Grid>
          <Grid item lg={4}>
          {/* <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 1.5,
              }}
            > */}
            <Box
              component="img"
              className={classes.securityImgs}
              src="/assets/images/platform_img.png"
              alt="Descriptive Alt Text"
            />
            {/* </motion.div> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Platform;

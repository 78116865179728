import {Box, Button, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";
import AuthScreenLeftBox from "app/components/unsorted/AuthScreenLeft";
import {Formik} from "formik";
import * as Yup from "yup";
import {postAxiosAPI} from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import {useNavigate} from "react-router-dom";
import {T_UseAccountStatus, useAuthManager} from "app/hooks";
import { AnimatedOtpInput } from "app/components/inputs";

const otpSchema = Yup.object().shape({
  otp: Yup.string().required("Otp is required"),
});

const OtpVerify = ({accountStatus, updateAccountStatus}: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const authManager = useAuthManager();

  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("countdownTime");
    return savedTime ? parseInt(savedTime, 10) : 60;
  });

  const sendOtp = async () => {
    setLoading(true);
    setTimeLeft(60);
    await postAxiosAPI("/verification/send-otp", {}, true)
      .then((res: any) => {
        console.log(res.data);
        setLoading(false);
        if (res.data.code !== 200) {
          setSnackbarMessage(res.data.message);
          setSeverity("error");
          setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setSnackbarMessage("Something went wrong!");
        setSeverity("error");
        setShowSnackbar(true);
      });
  };

  const verifyOtp = async (value: any) => {
    setLoading(true);
    const payload = {
      otp_code: value.otp,
    };
    await postAxiosAPI(
      "/verification/verify-otp",
      JSON.stringify(payload),
      true
    )
      .then((res: any) => {
        setLoading(false);
        const data = res.data;
        if (data?.success === true && data?.code === 200) {
          updateAccountStatus({ isEmailVerified: true });
        } else {
          setSnackbarMessage(data?.message);
          setSeverity("error");
          setShowSnackbar(true);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft <= 0) return;

    // Save timeLeft to localStorage on change
    localStorage.setItem("countdownTime", timeLeft.toString());

    // Decrease timeLeft by 1 every second
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    sendOtp();
  }, []);

  const showTimer = () => {
    if (timeLeft === 0) {
      return null;
    } else if (timeLeft < 10) {
      return "00:0" + timeLeft + "Sec";
    } else {
      return "00:" + timeLeft + "Sec";
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, handleSubmit: () => {}) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Grid container className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Grid item md={6} className={classes.leftGrid}>
        <AuthScreenLeftBox
          title="Welcome to TradeBP"
          bttomText=""
          routeName=""
          path=""
        />
      </Grid>
      <Grid item md={6} className={classes.rightGrid}>
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={otpSchema}
          onSubmit={(values: any) => {
            // same shape as initial values
            console.log(values);
            verifyOtp(values);
          }}
        >
          {({ errors, touched, handleChange, handleSubmit, values }: any) => (
            <Box 
              className={classes.formBox}
              onKeyDown={(event) => handleKeyDown(event, handleSubmit)}
            >
              <Typography
                variant="h3"
                align="center"
                className={classes.formHeading}
              >
                One time password
                <br /> verification
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                align="center"
                sx={{ fontWeight: "500" }}
              >
                We have sent a OTP to your email
              </Typography>
              <Box className={classes.otpBoxInput}>
                <AnimatedOtpInput
                  value={values.otp}
                  onChange={handleChange("otp")}
                  numInputs={6}
                  shouldAutoFocus={true}
                />
              </Box>
              {touched.otp && errors.otp && (
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={classes.errorMsg}
                >
                  {errors.otp}
                </Typography>
              )}
              <Box className={classes.btnsBox}>
                <Button
                  onClick={() => authManager.logoutUser()}
                  variant="outlined"
                  className={classes.submitBtnOutloined}
                >
                  Back
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Submit
                </Button>
              </Box>
              <Box className={classes.otpBox}>
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  className={
                    timeLeft < 1
                      ? classes.resentOtpText
                      : classes.resentOtpTextD
                  }
                  onClick={() => (timeLeft < 1 ? sendOtp() : null)}
                >
                  Resent OTP
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ margin: "auto 20px", width: "50px" }}
                >
                  {showTimer()}
                </Typography>
              </Box>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default OtpVerify;
const useStyles = makeStyles()((theme) => {
  return {
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    otpBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    otpBoxInput: {
      ...styles.flexDRS,
      justifyContent: "center",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },

    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    resentOtpTextD: {
      textDecorationLine: "underline",
      padding: "20px 0",
      color: "grey",
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      padding: "10px",
      margin: "20px 10px",
      boxSizing: "content-box",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px 0",
      textTransform: "none",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      // alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});

import {pallete} from "app/styles";

export const primaryCardsData = [
    {
        id: 1,
        title: "USDT total",
        innerData: [
            {
                id: 1,
                amount: "0.00",
                img: "assets/images/currencyT.png",
            },
        ],
        subtile: "Income",
        subtileAmt: "NA",
    },
    {
        id: 2,
        title: "Transactions total",
        innerData: [
            {
                id: 1,
                amount: "$ 0.00",
                img: null,
            },
            {
                id: 2,
                amount: "€ 0.00",
                img: null,
            },
        ],
        subtile: "Last transaction",
        subtileAmt: "NA",
    },
];

export const secondaryCardsData = [
    {
        id: 1,
        rate: "1.01%",
        type: "+",
        pair: "BTC/USD",
        img: "assets/icons/bitcoin.png",
        amount: "$29,610.44",
    },
    {
        id: 2,
        rate: "1.01%",
        type: "-",
        pair: "ETH/EUR",
        img: "assets/icons/leaf.png",
        amount: "€1,493.51",
    },
    {
        id: 3,
        rate: "1.01%",
        type: "+",
        pair: "BTC/USD",
        img: "assets/icons/bitcoin.png",
        amount: "$29,610.44",
    },
    {
        id: 4,
        rate: "1.01%",
        type: "-",
        pair: "ETH/EUR",
        img: "assets/icons/leaf.png",
        amount: "€1,493.51",
    },
];

export const tokenListIconsData = [
    {
        id: 0,
        color: pallete.selections.red,
        icon: "assets/icons/bitcoin.svg",
        name: "BTC",
        rate: "41%",
        price: "73,431",
    },
    {
        id: 1,
        color: pallete.selections.brown,
        icon: "assets/icons/eth.svg",
        name: "ETH",
        rate: "20%",
        price: "29,372",
    },
    {
        id: 2,
        color: pallete.selections.white,
        icon: "assets/icons/xrpIcon.svg",
        name: "XRP",
        rate: "17%",
        price: "17,623",
    },
    {
        id: 3,
        color: pallete.selections.green,
        icon: "assets/icons/dogIcon.svg",
        name: "DOGE",
        rate: "13%",
        price: "14,686",
    },
    {
        id: 4,
        color: pallete.selections.pink,
        icon: null,
        name: "Other",
        rate: "9%",
        price: "11,749",
    },
];

import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, {useState} from "react";
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";
import AuthScreenLeftBox from "app/components/unsorted/AuthScreenLeft";
import {Formik} from "formik";
import * as Yup from "yup";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {postAxiosAPI} from "app/utils/axiosApiRequests";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import {useNavigate} from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {useAccountStatus} from "app/hooks";

const passwrd_schema = Yup.object().shape({
  newPass: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Password must be 8-16 characters long, with at least one uppercase letter, one number, and one special character"
    ),
  confirmPass: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPass"), ""], "Passwords must match"),
});

const CreatePassword = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const { accountStatus, updateAccountStatus } = useAccountStatus();

  const [showPassword, setShowPassword] = React.useState({
    newPas: false,
    confirmPass: false,
  });
  const handleClickShowPassword = (value: string) => {
    setShowPassword((pre: any) => ({ ...pre, [value]: !pre[value] }));
  };

  const createPassword = async (value: any) => {
    setLoading(true);
    const payload = {
      confirm_password: value.confirmPass,
      password: value.newPass,
    };
    await postAxiosAPI(
      "/user-account/password/create",
      JSON.stringify(payload),
      true
    )
      .then((res: any) => {
        setLoading(false);
        if (res.data.code) console.log(res.data);
        updateAccountStatus({ isPasswordCreated: true });
        window.location.reload();
        // navigate("/setup-google-auth")
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Grid container className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Grid item md={4} className={classes.leftGrid}>
        <AuthScreenLeftBox
          title="Welcome to TradeBP"
          bttomText=""
          routeName=""
          path=""
        />
      </Grid>
      <Grid item md={8} className={classes.rightGrid}>
        <Formik
          initialValues={{
            newPass: "",
            confirmPass: "",
          }}
          validationSchema={passwrd_schema}
          onSubmit={(values: any) => {
            // same shape as initial values
            console.log(values);
            createPassword(values);
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
          }: any) => (
            <Box className={classes.formBox}>
              <Typography
                variant="h3"
                align="center"
                className={classes.formHeading}
              >
                Create your password
              </Typography>

              <Box className={classes.imputGrids}>
                <Typography color="primary" variant="body2">
                  New Password<span style={{ color: "#E13A05" }}>*</span>
                </Typography>
                <FormControl className={classes.inputBox} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword.newPas ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword("newPas")}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.newPas ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    autoComplete="off"
                    value={values.newPass}
                    onBlur={handleBlur("newPass")}
                    onChange={handleChange("newPass")}
                  />
                  {touched.newPass && errors.newPass && (
                    <Typography
                      variant="subtitle2"
                      align="left"
                      className={classes.errorMsg}
                    >
                      {errors.newPass}
                    </Typography>
                  )}
                </FormControl>
                <Typography color="primary" variant="body2">
                  Confirm Password
                  <span style={{ color: "#E13A05" }}>*</span>
                </Typography>
                <FormControl className={classes.inputBox} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword.confirmPass ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword("confirmPass")}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirmPass ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    autoComplete="off"
                    value={values.confirmPass}
                    onBlur={handleBlur("confirmPass")}
                    onChange={handleChange("confirmPass")}
                  />
                  {touched.confirmPass && errors.confirmPass && (
                    <Typography
                      variant="subtitle2"
                      align="left"
                      className={classes.errorMsg}
                    >
                      {errors.confirmPass}
                    </Typography>
                  )}
                </FormControl>
              </Box>
              <Box className={classes.btnsBox}>
                <Button
                  disabled
                  startIcon={<ArrowBackIosNewRoundedIcon />}
                  variant="outlined"
                  className={classes.submitBtnOutloined}
                >
                  Back
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Next Step
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CreatePassword;
const useStyles = makeStyles()((theme) => {
  return {
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    imputGrids: {
      padding: "0 10px",
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    createPasswordImg: {},
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      padding: "10px",
      margin: "20px 10px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});

import React from "react";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {useStyles} from "../Style";

const form_schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  fullname: Yup.string().required("Name is required"),
  message: Yup.string().required("Message is required"),
});

const handleMediaClick = (type: string) => {
  switch (type.toString().toLocaleLowerCase()) {
    case "x":
      window.open(`https://x.com/TradeBP1`);
      break;
    case "linkedin":
      window.open(`https://il.linkedin.com/company/tradebp?trk=ppro_cprof`);
      break;
  }
};

const Footer = ({ handleTabsClick }: any) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <>
       <Box  className={classes.footerBox}>
        <Grid
          container
          md={10}
          xs={12}
          sx={{ margin: "0 20px" }}
          className={classes.ContainerBox}
        >
          <Grid item md={6} xs={12}>
            <Box
              className={classes.menue}
              sx={{
                display: { xs: "none", md: "flex", lg: "flex", xl: "flex" },
              }}
            >
              <Typography
                onClick={() => handleTabsClick("home")}
                variant="h6"
                className={classes.menuTypos}
              >
                Home
              </Typography>
              <Typography
                onClick={() => handleTabsClick("product")}
                variant="h6"
                className={classes.menuTypos}
              >
                Product
              </Typography>
              <Typography
                onClick={() => handleTabsClick("platform")}
                variant="h6"
                className={classes.menuTypos}
              >
                Platform
              </Typography>

              <Typography
                onClick={() => handleTabsClick("aboutus")}
                variant="h6"
                className={classes.menuTypos}
              >
                About Us
              </Typography>
              <Typography
                onClick={() => handleTabsClick("faq")}
                variant="h6"
                className={classes.menuTypos}
              >
                FAQ
              </Typography>
              <Typography
                onClick={() => navigate("/privacy-policy")}
                variant="h6"
                className={classes.menuTypos}
              >
                Terms & Conditions
              </Typography>
              <Typography
                onClick={() => navigate("/privacy-policy")}
                variant="h6"
                className={classes.menuTypos}
              >
                Privacy Policy
              </Typography>
            </Box>
            <Box className={classes.imgMainBox}>
              <Box
                component="img"
                className={classes.secImgFooter}
                src="/assets/images/Security.png"
                alt="Descriptive Alt Text"
              />
              <Box sx={{}} className={classes.imgMainBox2}>
                <Box
                  component="img"
                  className={classes.socialIcons}
                  src="/assets/images/Twitter.png"
                  alt="Descriptive Alt Text"
                  onClick={() => handleMediaClick("X")}
                />
                <Box
                  component="img"
                  className={classes.socialIcons1}
                  src="/assets/images/linkedin.png"
                  alt="Descriptive Alt Text"
                  onClick={() => handleMediaClick("LinkedIn")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} mb={"auto"}>
            <Formik
              initialValues={{
                fullname: "",
                email: "",
                message: "",
              }}
              validationSchema={form_schema}
              onSubmit={(values: any) => {
                // handleAPIcall(values);
              }}
            >
              {({
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                values,
              }: any) => (
                <Box className={classes.formBox}>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    color="success"
                    autoComplete="off"
                    InputProps={{
                      sx: { color: "white" },
                    }}
                    className={classes.inputBox}
                    value={values.fullname}
                    onBlur={handleBlur("fullname")}
                    onChange={handleChange("fullname")}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                  />
                  {touched.fullname && errors.fullname && (
                    <Typography
                      variant="subtitle2"
                      align="left"
                      className={classes.errorMsg}
                    >
                      {errors.fullname}
                    </Typography>
                  )}
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    color="success"
                    autoComplete="off"
                    InputProps={{
                      sx: { color: "white" },
                    }}
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.email}
                    onBlur={handleBlur("email")}
                    onChange={handleChange("email")}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                  />
                  {touched.email && errors.email && (
                    <Typography
                      variant="subtitle2"
                      align="left"
                      className={classes.errorMsg}
                    >
                      {errors.email}
                    </Typography>
                  )}
                  <TextField
                    id="outlined-basic"
                    label="Your message"
                    multiline={true}
                    rows={4}
                    variant="outlined"
                    color="success"
                    InputProps={{
                      sx: { color: "white" },
                    }}
                    autoComplete="off"
                    className={classes.inputBox}
                    value={values.message}
                    onBlur={handleBlur("message")}
                    onChange={handleChange("message")}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                  />
                  {touched.message && errors.message && (
                    <Typography
                      variant="subtitle2"
                      align="left"
                      className={classes.errorMsg}
                    >
                      {errors.message}
                    </Typography>
                  )}
                  <Button
                    onClick={() => handleSubmit()}
                    variant="contained"
                    className={classes.btnAc}
                  >
                    Send a message
                  </Button>
                </Box>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;

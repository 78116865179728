/**
 * @file: Styles.ts
 * @date: 26 Feb 2024

 * @description: $
 */
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";


export const useStyles = makeStyles()((theme) => {
    return {
        mainBox: {
            backgroundColor: "#FCFBFE",
            height: "100vh",
            padding: "30px 50px",
            overflowY: "auto",
            [theme.breakpoints.down("lg")]: {
                height: "100%",
                padding: "100px 15px",
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "none"
            },
        },
        topCard1: {
            padding: "0 20px 10px 0",
            [theme.breakpoints.down("lg")]: {
                padding: "10px",
            },
        },
        topHeadingWeb: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        tradeBtn: {
            width: "100%",
            backgroundColor: pallete.primaryPurple,
            color: pallete.primaryWhite,
            textTransform: "none",
            borderRadius: "4px",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "22.4px",
            padding: "15px 0",
            marginTop: "20px",
        },
        inputOutlined: {
            width: "100%",
            margin: "10px 0",
        },
        grphnIcons: {
            height: "15px",
            width: "15px",
            padding: "2px",
        },
        ratingAmtN: {
            color: "#E13A05",
            fontWeight: 600,
        },
        ratingAmtP: {
            color: "#00E069",
            fontWeight: 600,
        },
        plusRating: {
            borderRadius: "4px",
            backgroundColor: "#E5FFF1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            padding: "2px 5px",
        },
        minusRating: {
            borderRadius: "4px",
            backgroundColor: "#FFF0EB",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            padding: "2px 5px",
        },
        card2Box1: {
            ...styles.flexDRC,
            justifyContent: "space-between",
        },
        bottomDataBox: {
            padding: "25px",
        },
        piceBOX: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        selectTokenBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "stretch",
        },
        btnsBS: {
            width: "100%",
            padding: "15px 5px",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 600,
            "&:hover": {
                backgroundColor: "#E0E3F2",
            },
        },
        btnsBSActive: {
            width: "100%",
            padding: "15px 5px",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 600,
            backgroundColor: "#E0E3F2",
        },
        buysellBtnBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "stretch",
        },
        buysellCard: {
            border: `1px soild ${pallete.primaryBlack}`,
            width: "100%",
            margin: "0 15px",
            boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
            borderRadius: "8px",
            [theme.breakpoints.down("lg")]: {
                margin: "10px 0",
            },
        },
        tabsText: {
            fontSize: "16px",
            fontWeight: 600,
            color: pallete.selections.blue,
            textTransform: "none",
            [theme.breakpoints.down("sm")]: {
                fontSize: "11px",
            },
        },
        startIconBoxCal: {
            height: "24px",
            width: "24px",
        },
        commonCardsTable: {
            padding: "12px",
            borderRadius: "8px",
            boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
            width: "100%",
            margin: "20px 0",
        },
        startIconBox: {
            backgroundColor: pallete.primaryGreen,
            borderRadius: "50%",
            padding: "5px",
            height: "15px",
            width: "15px",
        },
        iconsBtn: {
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            // lineHeight: "140%",
            padding: "10px 20px",
            borderRadius: "8px",
        },
        startIconBoxEth: {
            borderRadius: "50%",
            // padding: "5px",
            height: "24px",
            width: "24px",
        },
        commonCardsHBox: {
            ...styles.flexDRS,
            padding: "20px 0",
        },
        commonCards: {
            padding: "12px",
            borderRadius: "8px",
            width: "100%",
            boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
            [theme.breakpoints.down("lg")]: {
                margin: "10px 0"
            }
        },

    };
});

import React, {useState} from "react";
import {MoneyHubOrders} from "./MoneyHubOrders";
import {MoneyHubUserOrders} from "./MoneyHubUserOrders";
import {MoneyHubMerchantOrders} from "./MoneyHubMerchantOrders";


type T_MoneyHubIdState = {
    userId?: string;
    merchantId?: string;
}

export const MoneyHubOrdersView = (): React.JSX.Element => {

    const [moneyHubIdState, setMoneyHubIdState] = useState<T_MoneyHubIdState>({});

    const setMoneyHubIdFn = ({userId, merchantId}: Partial<T_MoneyHubIdState>) => {
        setMoneyHubIdState({userId, merchantId});
    }


    if (moneyHubIdState.userId) {
        return <MoneyHubUserOrders userId={moneyHubIdState.userId} setMoneyHubIdFn={setMoneyHubIdFn} />;
    }

    if (moneyHubIdState.merchantId) {
        return <MoneyHubMerchantOrders merchantId={moneyHubIdState.merchantId} setMoneyHubIdFn={setMoneyHubIdFn} />;
    }
    return <MoneyHubOrders setMoneyHubIdFn={setMoneyHubIdFn} />;

}

import {makeStyles, pallete, styles} from "app/styles";

const usePaymentMethodStyles = makeStyles()((theme) => {
    return {
        mainCard: {
            padding: "40px",
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            marginBottom: "10px",
            gap: "30px",
            display: "flex",
            flexDirection: "column",
        },
        titleBox: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        subtitleBox: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        title: {
            fontWeight: "bold",
        },
        paymentMethodBox: {
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            alignItems: "center",
        },
        paymentMethodCard: {
            padding: "20px",
            borderRadius: "10px",
            cursor: "pointer",
            border: "2px solid #E0E0E0",
            "&:hover": {
                backgroundColor: "#F5F5F5",
            },
        },
        paymentMethodCardSelected: {
            padding: "20px",
            borderRadius: "10px",
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: "#F5F5F5",
            cursor: "pointer",
        },
        submitButtonBox: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "20px"
        },
        submitButton: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            padding: "18px",
            borderRadius: "10px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
            },
            minWidth: "100px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
        },
    }
});

export {usePaymentMethodStyles}

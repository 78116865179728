import {useForm} from "./useForm";
import {FormSubmitButton, FormInput} from "./FormComponents";
import {FormProvider,FormHeading} from "./FormWrappers";

export const FORM = {
    useForm,
    Provider: FormProvider,
    Heading: FormHeading,
    Input: FormInput,
    SubmitButton: FormSubmitButton,
}

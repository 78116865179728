import React, {useEffect} from "react";
import {T_UseCryptohubPaymentState} from "./CryptohubPaymentHooks";
import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";
import {useNotification} from "app/providers";
import Loader from "app/components/unsorted/Loader";


type T_ViewProps = {
    paymentState: T_UseCryptohubPaymentState;
}

type T_AmountDetails = {
    value: number;
    currency: string;
}
export const InitiateTransactionView = ({paymentState}: T_ViewProps) => {
    const notification = useNotification();
    console.log(paymentState.transactionId);

    const apiConfig = ApiConfig.create(
        'crypto-hub-transaction', ['crypto-hub-transaction'],
    );

    const transactionRequest = RQ.usePostRequest({
        url: apiConfig.url,
        mutationKey: apiConfig.key,
    });

    useEffect(() => {
        transactionRequest.EXECUTE({
            payload:{request_uid:paymentState.transactionId},
            onSuccessFn: (data, message) => {
                paymentState.update({
                    transactionLink: data,
                    isTransactionInitiated: true
                });
            },
            onErrorFn: (data, message) => {
                notification.error(message);
            }
        })
    }, []);


    if (transactionRequest.isPending) {
        return <Loader isLoading={true} />
    }


    return (<>INITATE PAYMENT</>)
};

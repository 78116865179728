import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DashboardCardSecondary } from "app/components/unsorted/Cards";
import { pallete, styles } from "app/styles";
import TransactionsTable from "app/components/unsorted/TransactionTable";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import { useAccountStatus } from "app/hooks";

// const pieChartData = {
//   labels: ["BTC", "ETH", "XRP", "DOGE", "Other"],
//   datasets: [
//     {
//       label: "Crypto Distribution",
//       data: [73431, 29372, 17623, 14686, 11749],
//       backgroundColor: [
//         pallete.selections.red, // BTC
//         pallete.selections.brown, // ETH
//         pallete.selections.white, // XRP
//         pallete.selections.green, // DOGE
//         pallete.selections.pink, // Other
//       ],
//       borderColor: ["#FFFFFF"],
//       borderWidth: 5,
//       borderRadius: 15,
//       hoverOffset: 4,
//     },
//   ],
// };

// const pieChartOptions = {
//   cutout: "80%",
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       enabled: true,
//     },
//     centerText: {
//       text: "≈$146863", // Text you want to display
//     },
//   },
//   maintainAspectRatio: false,
// };

const Dashboard = () => {
  const { classes } = useStyles();
  const [loader, setLoader] = useState(false);
  const { accountStatus } = useAccountStatus();

  useEffect(() => {
    getAllCurrency();
  }, []);

  const getAllCurrency = async () => {
    setLoader(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <>
      <Box className={classes.mainBox}>
        <Typography
          variant="h4"
          color="primary"
          className={classes.topHeadingWeb}
        >
          Dashboard
        </Typography>

        <Grid container className={classes.cardsGridMain} key={"gridsec-p"}>
          <DashboardCardSecondary limit={8} mdSize={3} key={"gridsec"} />
        </Grid>

        <Grid container sm={12} item={true}>
          {/* <Card className={classes.commonCardsTable}> */}
            {/* <Box className={classes.commonCardsHBox}>
              <Typography variant="h6" color="primary">
                Transactions
              </Typography>
            </Box> */}
            <TransactionsTable />
          {/* </Card> */}
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
const useStyles = makeStyles()((theme) => {
  return {
    piechartGraphGrid: {
      paddingRight: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    piechartGraphGrid1: {
      paddingRight: "0px",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
        width: "100%",
      },
    },
    cardExp0: {
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      },
    },
    topCard0: {
      padding: "0 20px 0 0",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    topCard1: {
      padding: "0 10px 0 0",
      [theme.breakpoints.down("lg")]: {
        padding: "10px",
      },
    },
    iconsBtn: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      // lineHeight: "140%",
      padding: "10px 20px",
      borderRadius: "8px",
    },
    startIconBox: {
      backgroundColor: pallete.primaryGreen,
      borderRadius: "50%",
      padding: "5px",
      height: "15px",
      width: "15px",
    },
    startIconBoxEth: {
      borderRadius: "50%",
      // padding: "5px",
      height: "24px",
      width: "24px",
    },
    startIconBoxCal: {
      height: "24px",
      width: "24px",
    },
    endIconBox: {},
    pieChartGrid: {
      padding: "20px",
      [theme.breakpoints.down("lg")]: {
        padding: 0,
        margin: "0 10px",
      },
    },
    commonCardsHBox: {
      ...styles.flexDRS,
      // padding: "20px 0",
    },
    commonCards: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
    },
    commonCardsTable: {
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      width: "100%",
      margin: "20px 0",
    },
    cardsGridMain: {
      padding: "30px 0",
      // [theme.breakpoints.down("md")]: {
      //   display: "none",
      // },
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 50px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  };
});

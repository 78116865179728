import { makeStyles, pallete } from "app/styles";

export const useTwoPaneLayoutStyles = makeStyles()((theme) => {
  return {
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        padding: "30px 15px",
      },
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%",
      height: "100%",
      flexShrink: 0,
      backgroundImage: `url("assets/images/logingleftBG.svg")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});

export const useTwoPaneLayoutLeftBoxStyles = makeStyles()((theme) => {
  return {
    routeName: {
      paddingLeft: "10px",
      cursor: "pointer",
      color: pallete.primaryWhite,
      "&:hover": {
        color: pallete.black,
        textDecorationLine: "underline",
        transition: "ease-in",
      },
    },
    headingBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    headingTypo: {
      color: pallete.primaryWhite,
    },
    iconWeb: {
      height: "45px",
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer",
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      left: 0,
      bottom: "10px",
      right: 0,
    },
  };
});

export const useTwoPaneLayoutBottomTextStyles = makeStyles()((theme) => {
  return {
    routeName: {
      paddingLeft: "10px",
      cursor: "pointer",
      color: pallete.primaryWhite,
      "&:hover": {
        color: pallete.black,
        textDecorationLine: "underline",
        transition: "ease-in",
      },
    },
    headingBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    headingTypo: {
      color: pallete.primaryWhite,
    },
    iconWeb: {
      height: "45px",
      position: "absolute",
      top: 0,
      left: 0,
      cursor: "pointer",
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      left: 0,
      bottom: "10px",
      right: 0,
    },
  };
});

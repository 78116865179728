/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {useState} from "react";
import {useSessionStorage} from "app/hooks";

type T_TransactionLink = {
    transaction_id: number;
    amount: number;
    one_time_wallet_address: string;
}


export type T_CryptohubPaymentState = {
    transactionId: string;
    isOtpVerified: boolean;
    isConfirmed: boolean;
    isSimpleKycCompleted: boolean;
    isSimpleKycOtpVerified: boolean;
    isKycCompleted: boolean;
    isTransactionInitiated: boolean;
    transactionLink: T_TransactionLink;
    isTransactionCompleted: boolean;
    redirectUrl:string;
}

export type T_UseCryptohubPaymentState = T_CryptohubPaymentState & {
    update: (newStatus: Partial<T_CryptohubPaymentState>) => void;
}


export const useCryptohubPaymentState = (transactionId:any): T_UseCryptohubPaymentState => {

    const dataKey: string = transactionId;

    const _storage = useSessionStorage<T_CryptohubPaymentState>(dataKey, 
        {
            transactionId:'', 
            isOtpVerified: false, 
            isConfirmed: false, 
            isKycCompleted:false,
            isTransactionInitiated:false, 
            transactionLink:null, 
            isTransactionCompleted:false,
            isSimpleKycCompleted:false,
            isSimpleKycOtpVerified:false,
            redirectUrl:''
        }
    );

    const [paymentState, setPaymentState] = useState<T_CryptohubPaymentState>(() => {
        return _storage.getValue();
    });


    const updatePaymentState = (newTransactionState: Partial<T_CryptohubPaymentState>): void => {

        const updatedPaymentState: T_CryptohubPaymentState = {
            ...paymentState, ...newTransactionState
        };
        _storage.setValue(updatedPaymentState);
        setPaymentState(updatedPaymentState);
    }

    return {...paymentState, update: updatePaymentState};

}

import React, {lazy, Suspense} from 'react';



type T_CommonIconProps = {
    iconName?: string;
}

type T_MuiIconProps = {
    baseClassName?: string;
    children?: React.ReactNode;
    classes?: Object,
    color?: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | string;
    component?: React.ElementType;
    fontSize?: 'inherit' | 'large' | 'medium' | 'small' | string;
    sx?: any;
};


export const MuiIcon = ({iconName, ...props}: T_CommonIconProps & T_MuiIconProps): React.JSX.Element => {

    if (iconName && iconName.trim().length > 0){
        // Lazy-load the icon component, handling errors gracefully
        // const IconComponent = loadIconComponent(`@mui/icons-material/${name}`);



        const importPath = `@mui/icons-material/${iconName}`;
        //const IconComponent = async ():Promise<React.ElementType> => await import(`@mui/icons-material/${name}`).catch(() => ({default: () => <></>}));

        //const IconComponent = <></>;
        // const IconComponent = lazy(() =>
        //     import(`@mui/icons-material/${iconName}`).catch(() => ({
        //         default: () => <></>  // Fallback to an empty component if import fails
        //     }))
        // );

        // console.log({IconComponent,iconName: iconName,importPath} );



        const iconComponentProps:T_MuiIconProps = {
            baseClassName: props.baseClassName || 'material-icons',
            children: props.children || <></>,
            classes: props.classes || {},
            color: props.color || "inherit",
            component: props.component || undefined,
            fontSize: props.fontSize || "medium",
            sx: props.sx || [],
        }

        return (
            <Suspense fallback={<div></div>}>
                {/*<IconComponent {...iconComponentProps} />*/}
            </Suspense>
        );
    }
    return (<></>)
}


/**
 * Imports the required modules and components from React and Material-UI
 * React: The core React library
 * useState: A React hook for managing state
 * Box, Card, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography: Material-UI components
 * useDefaultTableContainerStyles: A custom hook for styling the table container
 */
import React from "react";
import {Box,TablePagination, Typography} from "@mui/material";
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import {DropdownMenuButton, T_MenuItemOptionList} from "app/components/buttons";
/**
 * Imports all the required fragments (components or utilities) from the './fragments' module
 */
import {
    StyledTableCell,
    StyledTableRow,
    T_CommonTableComponentProps,
    TableColumnHeader,
    TableNoRecordsFound
} from "./TableCommonFragments";
import * as SKL from "./TableSkeletons";
import {T_TableSearchPluginProps, TableSearchPlugin} from "./TableSearchPlugin";
import {T_DownloadPluginProps, TableDownloadCsvPlugin} from "./TableDownloadCsvPlugin";
import {BreadcrumbComponent,T_BreadcrumbList} from "app/components/common";

export type T_TableTitleProps = T_CommonTableComponentProps & {
    title: string;
}

export const TableTitle = ({classes, title, isLoading, ...opt}: T_TableTitleProps): React.JSX.Element => {
    return (
        <Box className={classes.tableTitleBox}>
            <Typography variant="h4" color="primary" className={classes.tableTitle}>
                {title}
            </Typography>
        </Box>
    )
}


export type T_TableHeadingsProps = T_CommonTableComponentProps & {
    // The headings of the table
    headings: string[];
}


export const TableHeadings = ({headings, isLoading, ...opt}: T_TableHeadingsProps): React.JSX.Element[] => {
    return headings.map((heading: string, index: number): React.JSX.Element => {
        const keyPrefix = `${opt.keyPrefix}-heading-${index}`;
        return (
            <StyledTableCell align="left" keyPrefix={keyPrefix} key={`#key-${keyPrefix}`}>
                {TableColumnHeader({heading, isLoading, keyPrefix})}
            </StyledTableCell>
        )
    });
}

export type T_TableContentProps = T_CommonTableComponentProps & {
    renderedTableRows: React.JSX.Element[][];
}


export const TableContent = ({renderedTableRows, ...opt}: T_TableContentProps): React.JSX.Element[] => {

    if (opt.isLoading) {
        return SKL.TableRowsSkeleton(
            opt.paginationState?.rowsPerPage || 10,
            opt.columnCount || 0,
        )
    }

    if (renderedTableRows && renderedTableRows.length > 0) {
        return renderedTableRows.map((renderedRow: any, index: number) => {
            const rowKey: string = `#T-ROW-${opt.keyPrefix}-${index}-SR-${index}`;
            return (<StyledTableRow keyPrefix={rowKey} key={`${rowKey}-key`}>{renderedRow} </StyledTableRow>)
        });
    }
    return [<TableNoRecordsFound colSpan={opt.columnCount || 1} key={`${opt.keyPrefix}-#T-ROW-NOT-FOUND`} />];

}

export type T_PaginationState = {
    rowsPerPage: number;
    currentPage: number;
}
export type  T_TablePaginatorProps = T_CommonTableComponentProps & {
    recordsCount: number;
    paginationState: T_PaginationState;
    onPaginateCallback: (rowsPerPage: number, currentPage: number) => void;
    rowsPerPageOptions?: number[];
}

export const TablePaginator = ({paginationState, ...opt}: T_TablePaginatorProps): React.JSX.Element => {

    if (opt.isLoading) {
        return SKL.TablePaginationSkeleton(opt.keyPrefix);
    }

    if (opt.recordsCount === 0) {
        return (<></>);
    }

    const rowsPerPageOptions = opt.rowsPerPageOptions || [1, 2, 3, 10, 25, 50, 100];

    const onPageChangeHandler = (event: any, value: any) => {
        const newPageValue = parseInt(value, 0);
        opt.onPaginateCallback(paginationState.rowsPerPage, newPageValue);
    };

    const onRowsPerPageChangeHandler = (event: any) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        opt.onPaginateCallback(newRowsPerPage, 0);
    };


    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={opt.recordsCount}
            rowsPerPage={paginationState.rowsPerPage}
            page={paginationState.currentPage}
            onPageChange={onPageChangeHandler}
            onRowsPerPageChange={onRowsPerPageChangeHandler}
            classes={opt.classes.paginationComponent}

        />
    )

}


export type T_TablePluginOptions = {
    search?: T_TableSearchPluginProps;
    download?: T_DownloadPluginProps;
}

export type T_TablePluginProps = T_TablePluginOptions & {
    classes: any;
};

export const TablePlugins = ({classes, search, download, ...opt}: T_TablePluginProps): React.JSX.Element => {
    return (
        <Box className={classes.tablePluginBox}>
            {search && TableSearchPlugin(search)}
            {download && TableDownloadCsvPlugin(download)}
        </Box>
    )
}


export type T_TableBreadcrumbsOptions = {
    breadcrumbs:T_BreadcrumbList;
}

export type T_TableBreadcrumbsProps = T_CommonTableComponentProps & T_TableBreadcrumbsOptions;

export const TableBreadcrumbs = (props: T_TableBreadcrumbsProps): React.JSX.Element => {
    return (<BreadcrumbComponent {...props} />);
}

import * as React from "react";
import { TabContent } from "app/components/tabs";
import {InviteTeamMembersView} from "./InviteTeamMembersView";
import { TeamMembersView } from "./TeamMembersView";


const Team = () => {

    const tabHeading: string = "Team";
    const resetUrlOnTabChange: boolean = true;

    const tabData = [
        { label: "Members", component: <TeamMembersView /> },
        { label: "Invitations", component: <InviteTeamMembersView /> },
    ];
    return TabContent({ tabHeading, tabData, resetUrlOnTabChange });
};
export default Team;

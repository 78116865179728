import { makeStyles } from "app/styles";

export const useDownloadButtonStyles = makeStyles()((theme) => {
  return {
    downloadButton: {
      textTransform: "none",
      margin: "0px 10px",
      paddingLeft: "10px",
    },
  };
});

import {RQ} from "app/hooks";
import {ApiConfig, USER_ACCOUNT_APIS as UAA} from "app/config";
import {T_AppMenuList} from "app/models";

export const USER_ACCOUNT_APIS = {
    CRUD: ApiConfig.create("user-account"),
    GET_MENUS: ApiConfig.create("user-account/menus"),
    GET_DETAILS: ApiConfig.create("user-account/details"),
}


export type T_AccountStatus = {
    kyc_is_completed: boolean;
    kyc_result: string;
    is_email_verified: boolean;
    email_verified_at: string,
    is_2fa_active: boolean
    is_password_setup_required: boolean,
    is_profile_completed: boolean,
}


export const useUserMenusQuery = (): RQ.T_QueryResult<T_AppMenuList> => {
    return RQ.useQueryRequest<T_AppMenuList>({
        url: USER_ACCOUNT_APIS.GET_MENUS.url,
        queryKey: USER_ACCOUNT_APIS.GET_MENUS.key,
        enabled: false,
    });
}

export const useAccountStatusQuery = (): RQ.T_QueryResult<T_AccountStatus> => {
    return RQ.useQueryRequest<T_AccountStatus>({
        url: UAA.GET_ACCOUNT_STATUS.url,
        queryKey: UAA.GET_ACCOUNT_STATUS.key,
        enabled: false,
    });
}

export type T_AccountDetails = {
    email: string;
    username: string;
    mobile_no: string;
    company_name: string;
    country: string;
    city: string;
    image: string;
    allow_password_change: boolean;
    trade_fees: number;
    money_hub_fees: number;
    crypto_hub_fees: number;
    role_id: string;
    role_name: string;
};

export const useAccountDetailsQuery = (): RQ.T_QueryResult<T_AccountDetails> => {
    return RQ.useQueryRequest<T_AccountDetails>({
        url: USER_ACCOUNT_APIS.GET_DETAILS.url,
        queryKey: USER_ACCOUNT_APIS.GET_DETAILS.key,
    });
}

export const useUpdateAccountsDetailsQuery = (): RQ.T_MutationResult<T_AccountDetails> => {
    return RQ.usePutRequest<T_AccountDetails>({
        url: UAA.GET_ACCOUNT_DETAILS.url,
        mutationKey: UAA.GET_ACCOUNT_DETAILS.key,
    });
}


export type T_UserAccount =  {
    id: string;
    full_name: string;
    email: string;
    dob: string;
    image:string;
    mobile: string | null;
    company_name: string;
    is_2fa_active: boolean;
    kyc_is_verified: boolean;
    kyc_is_in_progress: boolean;
    kyc_is_completed: boolean;
    trade_fees: number;
    money_hub_fees: number;
    crypto_hub_fees: number;
    email_verified_at:string;
};

export const useUserAccountsListQuery = (): RQ.T_QueryResult<T_UserAccount[]> => {
    return RQ.useQueryRequest<T_UserAccount[]>({
        url: USER_ACCOUNT_APIS.CRUD.url,
        queryKey: USER_ACCOUNT_APIS.CRUD.key,
    });
}

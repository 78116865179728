/**
 * @file: Styles.ts
 * @date: 11 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";


export const usePrivateLayoutStyles = makeStyles()((theme) => {
    return {
        mobileGrid: {
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        webGrid: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
            overflow: "hidden",
            height: "100vh",
        },
        sideBarGrid: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        childGrid: {},
    };
});

export const useHeaderStyles = makeStyles()((theme) => {
    return {
        iconWeb: {
            width: "45px",
            height: "45px",
        },
        toolBarMain: {
            ...styles.flexDRS,
            padding: "10px",
        },
        menueIcon: {
            color: pallete.primaryPurple,
            fontSize: "40px",
        },
        appBarMain: {
            backgroundColor: pallete.primaryWhite,
        },
    };
});

export const useSidebarStyles = makeStyles()((theme) => {
    return {
        listImgBtn: {
            ...styles.flexDRC,
        },
        personIconBox: {
            backgroundColor: pallete.primaryPurple,
            height: "120px",
            width: "120px",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0 10px 0",
        },
        listItemText: {
            "&:hover": {
                color: pallete.primaryWhite,
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22.4px",
            },
            transition: "background-color 300ms",
            color: pallete.primaryBlack,
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22.4px",
        },
        listItemTextActive: {
            "&:hover": {
                color: pallete.primaryPurple,
                fontFamily: "Montserrat",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22.4px",
            },
            transition: "background-color 300ms",
            color: pallete.primaryWhite,
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22.4px",
        },
        listIcons: {
            height: "24px",
            width: "24px",
        },
        listItemBtn: {
            "&:hover": {
                backgroundColor: pallete.primaryPurple, // or any color you prefer
                // Add more styles as needed
            },
            transition: "background-color 300ms",
            width: "100%",
            //   padding:"5px 0"
        },
        listItemBtnActive: {
            "&:hover": {
                backgroundColor: pallete.primaryWhite, // or any color you prefer
            },
            backgroundColor: pallete.primaryPurple,
            transition: "background-color 300ms",
            width: "100%",
            //   padding:"5px 0"
        },
        listBox: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "20px",
            height: "calc(100vh - 270px)",
            overflow: "hidden",
            overflowY: "auto",
        },
        namesBox: {
            display: "flex",
            flexDirection: "row",
            padding: "10px 0",
            cursor: "pointer",
        },
        downIcn: {
            height: "24px",
            width: "24px",
            margin: "auto 5px",
        },
        upperBox: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 0",
        },
        userImg: {
            height: "100px",
            width: "100px",
            margin: "10px 0",
        },
        mainBox: {
            display: "flex",
            flexDirection: "column",
            padding: "24px 0",
        },
        iconWeb: {
            width: "100%",
            height: "45px",
        },
    };

});

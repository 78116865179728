import React from 'react'
import ComingSoonPage from "app/components/unsorted/CommingSoon";

const TermsAndCondition = () => {
  return (
    <>
    <ComingSoonPage/>
    </>
  )
}

export default TermsAndCondition

import React from "react";
import {Box, Button, Divider, IconButton, Typography} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {
    T_Asset,
    useAssetsList,
    useCryptohubTransactionRequest,
    useCurrencyExchangeRatesQuery,
} from "./CryptohubPaymentQueries";
import {T_UseCryptohubPaymentState} from "./CryptohubPaymentHooks";
import Loader from "app/components/unsorted/Loader";

type T_ViewProps = {
    classes: any;
    paymentState: T_UseCryptohubPaymentState;
    transactionId: string | any;
};

type T_AmountDetails = {
    amount: number;
    currency: string;
    asset: string;
    assetAmount: number;
    fees?: number;
};

const AmountConfirmationView = ({
                                    classes,
                                    paymentState,
                                    transactionId,
                                }: T_ViewProps) => {
    const transactionRequest = useCryptohubTransactionRequest(transactionId);
    const assetsList = useAssetsList();
    const amountDetails: T_AmountDetails = {} as T_AmountDetails;

    const getAssetSymbol = (asset: string) => {
        let assetSymbol = "";

        if (assetsList.result) {
            const assetObj = assetsList.result.find(
                (assetObj: T_Asset) => assetObj.fireblocks_asset_id === asset
            ) as T_Asset;
            if (assetObj) assetSymbol = assetObj.acts_as;
        }

        return assetSymbol;
    };

    const exchangeRates = useCurrencyExchangeRatesQuery(
        getAssetSymbol(transactionRequest.result?.fireblocks_asset_id),
        transactionRequest.result?.currency_symbol
    );

    if (transactionRequest.result) {
        amountDetails.amount = transactionRequest.result.amount;
        amountDetails.currency = transactionRequest.result.currency_symbol;
        amountDetails.asset = transactionRequest.result.fireblocks_asset_id;
        amountDetails.assetAmount = amountDetails.amount / exchangeRates.result?.best_price;
        amountDetails.fees = transactionRequest.result.fees / exchangeRates.result?.best_price;
    }

    const updatePaymentStateOnNext = () => {
        paymentState.update({
            isConfirmed: true,
            transactionId: transactionRequest.result.id,
        });
    };

    const onNext = () => {
        updatePaymentStateOnNext();
    };

    if (assetsList.isResolving || transactionRequest.isResolving || !amountDetails.assetAmount) {
        return <Loader isLoading={true}/>;
    }

    return (
        <Box className={classes.mainCard}>
            <Box className={classes.confirmationCard}>
                <Box className={classes.ConfirmationInnerCard}>
                    <Typography variant="h2">
                        Would you like to proceed?
                    </Typography>
                    <Divider/>
                    {/* <Box className={classes.midBoxC1}>
                        <Box className={classes.currencyBox}>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {" "}
                                Selected Currency :{" "}
                            </Typography>
                            <Typography variant="h5">
                                {amountDetails.amount}
                            </Typography>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {amountDetails.currency}
                            </Typography>
                        </Box>
                    </Box> */}
                    <Box className={classes.midBoxC1}>
                        <Box className={classes.cryptoBox}>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {" "}
                                Selected Crypto :{" "}
                            </Typography>
                            <Typography variant="h5">
                                {amountDetails.assetAmount}
                            </Typography>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {getAssetSymbol(amountDetails.asset)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.midBoxC1}>
                        <Box className={classes.cryptoBox}>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {" "}
                                Fees :{" "}
                            </Typography>
                            <Typography variant="h5">
                                {amountDetails?.fees || 0}
                            </Typography>
                            <Typography variant="h5" fontWeight={"bold"}>
                                {getAssetSymbol(amountDetails.asset)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.termsAndConditions}>
                        <Typography variant="h6" fontStyle={"italic"}>
                            By proceeding with this deposit, you are agreeing
                            with our
                            <span>
                                {" "}
                                <Link to="/privacy-policy" target="_blank">
                                    Terms and Conditions
                                </Link>
                            </span>
                        </Typography>
                    </Box>
                    <Box className={classes.confirmationButtonBox}>
                        <Button
                            className={classes.confirmationButton}
                            variant="contained"
                            color="primary"
                            onClick={onNext}
                        >
                            Yes, Proceed
                            <IconButton>
                                <ArrowForward/>
                            </IconButton>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export {AmountConfirmationView};

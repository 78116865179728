import {makeStyles, pallete} from "app/styles";


export const useTabContentStyles = makeStyles()((theme) => {
    return {
        basicTabHeaderBox: {
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
        },
        tabsText: {
            fontSize: "16px",
            fontWeight: 600,
            color: pallete.selections.blue,
            textTransform: "none",
        },
        basicTabHeading: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        mainBox: {
            backgroundColor: "#FCFBFE",
            height: "91vh",
            padding: "30px",
            overflowY: "auto",
            [theme.breakpoints.down("lg")]: {
                height: "100%",
                padding: "100px 5px",
            },
        }
    }

});

export const headerBoxStyles = {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
};

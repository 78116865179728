import React, {ReactElement} from "react";


import Dashboard from "./dashboard/Dashboard";
import {Trading} from "./trading";
import CryptoHubView from "./cryptohub";
import BillingBalance from "./billing/BillingBalance";
import Security from "./security/Security";
import Notification from "./notifications/Notification";
import MoneyHubView from "./moneyhub";
import Settings from "./settings/Settings";
import Team from "./team";



const CUSTOMER_VIEWS_MAPPING: Record<string, ReactElement> = {
    "/dashboard": <Dashboard />,
    "/trade": <Trading />,
    "/cryptohub": <CryptoHubView />,
    "/billing-balance": <BillingBalance />,
    "/team": <Team />,
    "/security": <Security />,
    "/notifications": <Notification />,
    "/moneyhub": <MoneyHubView />,
    "/settings": <Settings />,

};
export default CUSTOMER_VIEWS_MAPPING;

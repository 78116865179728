import {QAuth} from "app/queries";
import {useLoader, useNotification} from "app/providers";
import {useGoogleLogin} from "@react-oauth/google";
import {SuccessLoginResponse, useAuthManager} from "./useAuthManager";

type T_AuthenticatorResult = {
    loginWithCredentials: (user: string, password: string, auth_type: string) => void;
    loginWithGoogle: () => void;
}


export const useGoogleAuthentication = () => {

    const signIn = useGoogleLogin({
        onSuccess: (tokenResponse) => tokenResponse,
    });

    const fetchUserInfo = async (accessToken: any) => {

    }

    return {signIn,fetchUserInfo}

}


export const useAuthenticator = (): T_AuthenticatorResult => {
    const authManager = useAuthManager();
    const loader = useLoader();
    const loginQuery = QAuth.useLoginQuery();
    const notification = useNotification();

    const onSuccessFn = async (data: SuccessLoginResponse, message: string) => {
        const loginStatus = await authManager.loginUser(data.access, data.refresh);
        notification.success(loginStatus);
    }

    const onErrorFn = (error: any, message: string) => (notification.error(message));

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (tokenResponse: any) => {
            return loginWithCredentials("token",
                tokenResponse.access_token, "google");
        }, ux_mode: "redirect",
    });

    const loginWithCredentials = async (user: string, password: string, auth_type: string): Promise<void> => {
        loader.show();
        const payload = {user, password, auth_type};
        await loginQuery.EXECUTE({payload, onSuccessFn, onErrorFn});
        loader.hide();
    };

    return {loginWithGoogle, loginWithCredentials, ...loginQuery};
}

import {makeStyles, pallete} from "app/styles";

export const useLoginStyles = makeStyles()((theme) => {
    return {
        routeName: {
            cursor:'pointer'
        },
        bottomText: {
            position: "absolute",
            bottom: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        errorMsg: {
            color: "red",
            paddingLeft: "5px",
        },
        bottomBTNBox: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
        },
        bottomBtns: {
            width: "100%",
            padding: "10px 20px",
            margin: "10px 5px",
            borderRadius: "2px",
            background: "#FFF",
            boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
            textTransform: "none",
        },
        iconGA: {
            height: "18px",
            width: "18px",
        },
        orText: {
            fontWeight: "500",
            lineHeight: "28px",
            color: "#36205F80",
        },
        orBox: {
            display: "flex",
            flexFlow: "row",
        },
        submitBtn: {
            width: "100%",
            padding: "12px 0",
            margin: "10px 0",
            textTransform: "none",
        },
        inputBox: {
            width: "100%",
            margin: "10px 0",
        },
        rightGrid: {
            padding: "30px 100px",
            width: "100%",
            [theme.breakpoints.down("lg")]: {
                // height: "100%",
                padding: "30px 15px",
            },
        },
        formHeading: {
            fontWeight: 700,
            lineHeight: "44.8px",
            color: pallete.primaryBlack,
            padding: "10px 0",
        },
        mainGrid: {
            height: "100vh",
            width: "100%",
        },
        formBox: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            position: "relative",
        },
        leftGrid: {
            padding: "30px 100px",
            backgroundColor: pallete.primaryBlack,
            width: "100%", // specify width
            height: "100%", // specify height
            flexShrink: 0, // apply flex-shrink
            backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
            backgroundSize: "cover", // cover the container with the background image
            backgroundPosition: "center", // center the background image
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
        },
    };
});

import React, {useEffect, useState} from "react";
import {Box, Button, Grid, TextField, Typography,} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";
import {getAxiosAPI, postAxiosAPI} from "app/utils/axiosApiRequests";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Formik} from "formik";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import * as Yup from "yup";
import dayjs from "dayjs";
import {SearchInput} from "app/components/inputs";
import {useNavigate} from "react-router-dom";

const form_schema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    fullName: Yup.string().required("Full name is required"),
    dob: Yup.string()
        .required("Date of birth is required")
        .test("dob", "You must be at least 18 years old", (value) => {
            const today = dayjs();
            const dob = dayjs(value);
            const age = today.diff(dob, "year");
            return age >= 18;
        }),
    currency: Yup.string().required("Country is required"),
    asset: Yup.string().required("Asset is required"),
    country: Yup.string().required("Country is required"),
    amount: Yup.string().required("Amount is required"),
});


const CryptoFormView = () => {
    const {classes} = useStyles();
    const [currency, setCurrency] = useState([]);
    const [country, setCountry] = useState([]);
    const [asset, setAsset] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState<"success" | "error">("success");
    const navigate = useNavigate();

    const getCurrency = async () => {
        setLoading(true);
        await getAxiosAPI("/currency")
            .then((res: any) => {
                setLoading(false);
                console.log(res.data.data);
                setCurrency(res.data.data);
            })
            .catch((err: any) => {
                setLoading(false);
                console.log(err);
            });
    };
    const getAsset = async () => {
        setLoading(true);
        await getAxiosAPI("/asset")
            .then((res: any) => {
                setLoading(false);
                console.log(res.data.data);
                setAsset(res.data.data);
            })
            .catch((err: any) => {
                setLoading(false);
                console.log(err);
            });
    };
    const getCountry = async () => {
        setLoading(true);
        await getAxiosAPI("/country")
            .then((res: any) => {
                setLoading(false);
                console.log(res.data.data);
                setCountry(res.data.data);
            })
            .catch((err: any) => {
                setLoading(false);
                console.log(err);
            });
    };

    function formatDate(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleSubmit = async (values: any) => {
        console.log(values);
        setLoading(true);
        const birthDate = values.dob.split("T")[0];
        const validity = formatDate(values.validTill);
        const payload = {
            "user_email": values.email,
            "api_key": values.apiKey,
            "amount": parseFloat(values.amount),
            "currency_id": values.currency,
            "asset_id": values.asset,
            "valid_till": validity,
            "redirect_url": values.redirectUrl,
            "full_name": values.fullName,
            "birthday": birthDate,
            "country": values.country,
        };
        await postAxiosAPI(
            "/crypto-hub-transaction-request",
            JSON.stringify(payload)
        )
            .then((res: any) => {
                setLoading(false);
                console.log(res.data);
                if (res.data.code === 200) {
                    setShowSnackbar(true);
                    setSeverity("success");
                    setSnackbarMessage("New User created successfully");
                    window.open(`/cryptohub-payment/${res.data.data.transaction_uid}`, '_blank');
                } else {
                    setSeverity("error");
                }
            })
            .catch((err: any) => {
                setLoading(false);
                console.log(err);
            });
    };
    useEffect(() => {
        getCurrency();
        getAsset();
        getCountry();
    }, []);


    return (
        <Box className={classes.mainBoxForm}>
            <Loader isLoading={loading}/>
            <MessageAlert
                showBar={showSnackbar}
                message={snackbarMessage}
                severity={severity}
                onClose={() => setShowSnackbar(false)}
            />
            <Typography variant="h4" gutterBottom>
                CRYPTO-HUB
            </Typography>
            <Formik
                initialValues={{
                    email: "",
                    apiKey: "",
                    amount: 0.0,
                    currency: "",
                    asset: "",
                    validTill: "",
                    fullName: "",
                    dob: null,
                    country: "",
                    redirectUrl: "",
                }}
                validationSchema={form_schema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
            >
                {({
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      values,
                      setFieldValue,
                  }: any) => (
                    <Grid container>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                className={classes.inputBox}
                                value={values.email}
                                onBlur={handleBlur("email")}
                                onChange={handleChange("email")}
                            />
                            {touched.email && errors.email && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.email}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                className={classes.inputBox}
                                value={values.fullName}
                                onBlur={handleBlur("fullName")}
                                onChange={handleChange("fullName")}
                            />
                            {touched.fullName && errors.fullName && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.fullName}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Api Key"
                                variant="outlined"
                                className={classes.inputBox}
                                value={values.apiKey}
                                onBlur={handleBlur("apiKey")}
                                onChange={handleChange("apiKey")}
                            />
                            {touched.apiKey && errors.apiKey && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.apiKey}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{width: "100%", margin: "10px 0"}}
                                    label="Date Of Birth"
                                    value={values.dob}
                                    onChange={(newValue) => {
                                        // Update Formik's state for the 'dob' field with the new date value
                                        setFieldValue(
                                            "dob",
                                            newValue ? newValue.toISOString() : ""
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                            {touched.dob && errors.dob && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.dob}
                                </Typography>
                            )}
                        </Grid>
                        <Grid container>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.gridItemPadding}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="Amount"
                                    variant="outlined"
                                    className={classes.inputBox}
                                    value={values.amount}
                                    onBlur={handleBlur("amount")}
                                    onChange={handleChange("amount")}
                                />
                                {touched.amount && errors.amount && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.amount}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.gridItemPadding}
                            >
                                <SearchInput
                                    options={currency}
                                    label="Select Currency"
                                    onSelect={(field: any, value: any) =>
                                        setFieldValue(field, value)
                                    }
                                    displayValue="name"
                                    formikValue="currency"
                                />
                                {touched.currency && errors.currency && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.currency}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.gridItemPadding}
                            >
                                <SearchInput
                                    options={asset}
                                    label="Select Asset"
                                    onSelect={(field: any, value: any) =>
                                        setFieldValue(field, value)
                                    }
                                    displayValue="name"
                                    formikValue="asset"
                                />
                                {touched.asset && errors.asset && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.asset}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.gridItemPadding}
                            >
                                <SearchInput
                                    options={country}
                                    label="Select Country"
                                    onSelect={(field: any, value: any) =>
                                        setFieldValue(field, value)
                                    }
                                    displayValue="name"
                                    formikValue="country"
                                />
                                {touched.country && errors.country && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.country}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.gridItemPadding}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="Redirect Url"
                                    variant="outlined"
                                    className={classes.inputBox}
                                    value={values.redirectUrl}
                                    onBlur={handleBlur("redirectUrl")}
                                    onChange={handleChange("redirectUrl")}
                                />
                                {touched.redirectUrl && errors.redirectUrl && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.redirectUrl}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.gridItemPadding}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="Valid Till"
                                    name="dateTime"
                                    type="datetime-local" // Set type to datetime-local
                                    value={values.validTill}
                                    onBlur={handleBlur("validTill")}
                                    onChange={handleChange("validTill")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    margin="normal"
                                />
                                {touched.validTill && errors.validTill && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.validTill}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Box className={classes.buttonBox}>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        variant="contained"
                                        className={classes.submitBtn}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Box>
    );
};

export default CryptoFormView;
const useStyles = makeStyles()((theme) => {
    return {
        mainBoxForm: {
            paddingTop: "20px",
        },
        buttonBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "20px 10px",
        },
        gridItemPadding: {
            padding: "5px 10px",
        },
        routeName: {},
        bottomText: {
            position: "absolute",
            bottom: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        errorMsg: {
            color: "red",
            paddingLeft: "5px",
        },
        termsText: {
            color: pallete.primaryBlack,
            textDecorationLine: "underline",
            margin: "auto 0",
        },
        checkBoxB: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        mainBox: {
            backgroundColor: "#FCFBFE",
            height: "91vh",
            padding: "30px 50px",
            overflowY: "auto",
            [theme.breakpoints.down("lg")]: {
                height: "100%",
                padding: "80px 15px",
            },
        },
        topHeadingWeb: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        bottomBTNBox: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
        },
        bottomBtns: {
            width: "100%",
            padding: "10px 20px",
            margin: "10px 5px",
            borderRadius: "2px",
            background: "#FFF",
            boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
            textTransform: "none",
        },
        iconGA: {
            height: "18px",
            width: "18px",
        },
        orText: {
            fontWeight: "500",
            lineHeight: "28px",
            color: "#36205F80",
        },
        orBox: {
            display: "flex",
            flexFlow: "row",
        },
        submitBtn: {
            ...styles.webButton,
            padding: "10px 60px",
        },
        inputBox: {
            width: "100%",
            margin: "10px 0",
        },
        rightGrid: {
            padding: "10px",
            width: "100%",
            [theme.breakpoints.down("lg")]: {
                // height: "100%",
                padding: "30px 15px",
            },
        },
        formHeading: {
            fontWeight: 700,
            lineHeight: "44.8px",
            color: pallete.primaryBlack,
            padding: "10px 0",
        },
        mainGrid: {},
        formBox: {},
        leftGrid: {
            padding: "30px 100px",
            backgroundColor: pallete.primaryBlack,
            width: "100%", // specify width
            height: "100%", // specify height
            flexShrink: 0, // apply flex-shrink
            backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
            backgroundSize: "cover", // cover the container with the background image
            backgroundPosition: "center", // center the background image
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
        },
    };
});

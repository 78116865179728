import React, {DetailedHTMLProps, FormHTMLAttributes} from "react";
import {T_UseFormResult,T_FormInstance} from "./useForm";
import {Box, Grid, Typography} from "@mui/material";

import {useFormStyles} from "./FormStyles";




type T_FormHeadingProps = {
    title?: string;

}

export const FormHeading = ({title, ...props}: T_FormHeadingProps) => {
    const {classes} = useFormStyles();
    return (<Typography variant="h3" className={classes.formHeading}>{title || ''}</Typography>);
}


/**
 * Type definition for FormProvider component props.
 * Extends DetailedHTMLProps for form elements and includes children and form properties.
 */
type T_FormProviderProps = DetailedHTMLProps<FormHTMLAttributes<any>, any> & {
    children: React.ReactNode;
    form: T_FormInstance;
    heading?:string;
    [key: string]: any;
}

/**
 * FormProvider component.
 * Wraps form elements and provides form context using React Hook Form's useForm hook.
 * @param {T_FormProviderProps} param0 - Destructured props: children, form, and other props.
 * @returns {React.JSX.Element} JSX element for the form.
 */
export const FormProvider = ({children, form, ...props}: T_FormProviderProps): React.JSX.Element => {
    const {classes} = useFormStyles();

    return (
        <Box className={classes.formContainerBox} >
            <form onSubmit={form.handleSubmit(form.onSubmit)} {...props}>

                {props.heading && <FormHeading title={props.heading} />}

                {children}
            </form>
        </Box>
    );
}


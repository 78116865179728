import {IconButton, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import React, {useState} from "react";
import {T_CommonTableComponentProps} from "./TableCommonFragments";
import {TablePluginsSkeleton,} from "./TableSkeletons";

/**
 * Define options for the TableSearchPlugin.
 */
export type T_TableSearchPluginOptions<Tdata = any> = {
    searchKeys: string[];
    filterFunction?: (searchValue: string | number, searchDataSet: Tdata[]) => Tdata[];
};

/**
 * Props for the TableSearchPlugin component.
 */
export type T_TableSearchPluginProps<Tdata = any> = T_TableSearchPluginOptions & T_CommonTableComponentProps & {
    tableData: Tdata[] | undefined;
    searchFnCallback?: (filteredData: Tdata[]) => void;
};

type T_FilterFnProps<Tdata = any> = T_TableSearchPluginOptions & {
    searchValue: string;
    searchDataset: Tdata[]
}

/**
 * Function to filter table data using a search value and search keys.
 */
const filterTableDataUsingSearchValue = ({searchValue, searchKeys, searchDataset, ...opt}: T_FilterFnProps): any[] => {
    const searchInput: string = searchValue.toString().toLowerCase();
    const isSpecialSearchCase = searchInput.startsWith("?");
    const searchString: string = isSpecialSearchCase ? searchInput.substring(1) : searchInput;

    if (searchString.trim().length === 0) {
        return searchDataset;
    }

    if (opt.filterFunction) {
        return opt.filterFunction(searchInput, searchDataset);
    }

    return searchDataset.filter((item: any): boolean => {
        let filterStatus: boolean = false;
        for (const fieldName of searchKeys) {
            const rawFieldValue: any = item?.[fieldName];
            const fieldValue: string = rawFieldValue ? rawFieldValue.toString().toLowerCase() : '';

            const matchStatus: boolean = (
                isSpecialSearchCase ? fieldValue.includes(searchString) : fieldValue.startsWith(searchString)
            );

            filterStatus = filterStatus || matchStatus;

            if (filterStatus === true) {
                break;
            }
        }
        return filterStatus;
    });
}




/**
 * TableSearchPlugin component for adding search functionality to a table.
 */
export const TableSearchPlugin = ({isLoading, keyPrefix, ...opt}: T_TableSearchPluginProps): React.JSX.Element => {
    const [searchInput, setSearchInput] = useState("");

    const searchDataset: any[] = opt.tableData || [];

    /**
     * Function to update the search input and filter the dataset accordingly.
     */
    const updateSearchInput = (searchValue: string): void => {
        setSearchInput(searchValue);
        const filteredDataSet: any[] = filterTableDataUsingSearchValue({
            searchValue, searchDataset, ...opt
        });

        const slicedFilteredDataSet = filteredDataSet.slice(
            opt.paginationState?.currentPage || 0,
            opt.paginationState?.rowsPerPage || 10
        );

        opt.searchFnCallback && opt.searchFnCallback(slicedFilteredDataSet);
    };

    /**
     * Handler for clearing the search input.
     */
    const handleSearchIconClick = (): void => {
        if (searchInput.trim().length > 0) {
            updateSearchInput('')
        }
    }

    if (isLoading) {
        return TablePluginsSkeleton({keyPrefix});
    }

    return (
        <TextField
            label="Search"
            value={searchInput}
            onChange={(e: any) => updateSearchInput((e.target.value || ''))}
            defaultValue="Small"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle search icon " onClick={handleSearchIconClick}>
                            {searchInput.trim().length > 0 ? <ClearIcon /> : <SearchIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

import {getTokens} from "./Helpers";
import {I_ApiRequestParams, I_HttpRequestParams, T_ArrayOrObject} from "./Interfaces";
import {HttpRequest} from "./HttpRequest";


export class ApiRequest {

    public static getRequestHeaders(): object {
        const {accessToken} = getTokens();
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...(accessToken ? {'Authorization': `Bearer ${accessToken}`} : {}),
        }
    }

    public static Request<T = T_ArrayOrObject>(requestParams: I_HttpRequestParams): HttpRequest<T> {
        console.log({requestParams});
        return new HttpRequest<T>({
            ...requestParams, headers: {...(requestParams.headers || {}), ...ApiRequest.getRequestHeaders()}
        });
    }

    public static Get<T = T_ArrayOrObject>({url, params}: I_ApiRequestParams): HttpRequest<T> {
        return ApiRequest.Request<T>({method: "GET", url, params});
    }

    public static Post<T = T_ArrayOrObject>({url, payload, params}: I_ApiRequestParams): HttpRequest<T> {
        return ApiRequest.Request<T>({method: "POST", url, payload, params});
    }

    public static Put<T = T_ArrayOrObject>({url, payload, params}: I_ApiRequestParams): HttpRequest<T> {
        return ApiRequest.Request<T>({method: "PUT", url, payload, params});
    }

    public static Delete<T = T_ArrayOrObject>({url, payload, params}: I_ApiRequestParams): HttpRequest<T> {
        return ApiRequest.Request<T>({method: "DELETE", url, payload, params});
    }

}

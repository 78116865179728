import React from "react";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import BasicTabs from "app/components/unsorted/Tabs";

export const Trading = () => {
    const {classes} = useStyles();
    const secondaryCardsData = [
        {
            id: 1,
            rate: "1.01%",
            type: "+",
            pair: "BTC/USD",
            img: "assets/icons/bitcoin.png",
            amount: "$29,610.44",
        },
        {
            id: 2,
            rate: "1.01%",
            type: "-",
            pair: "ETH/EUR",
            img: "assets/icons/leaf.png",
            amount: "€1,493.51",
        },
        {
            id: 3,
            rate: "1.01%",
            type: "+",
            pair: "BTC/USD",
            img: "assets/icons/bitcoin.png",
            amount: "$29,610.44",
        },
        {
            id: 4,
            rate: "1.01%",
            type: "-",
            pair: "ETH/EUR",
            img: "assets/icons/leaf.png",
            amount: "€1,493.51",
        },
    ];

    return (
        <>
            <Box className={classes.mainBox}>
                <BasicTabs />
            </Box>
        </>
    );
};

export {TradingPage} from "./TradingPage";

const useStyles = makeStyles()((theme) => {
    return {
        mainBox: {
            backgroundColor: "#FCFBFE",
            height: "91vh",
            padding: "30px 50px",
            overflowY: "auto",
            [theme.breakpoints.down("lg")]: {
                height: "100%",
                padding: "100px 15px",
            },
        },
    };
});

import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, {useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {styles} from "app/styles";
import {styled} from "@mui/material/styles";

const table0 = [
  {
    category: "Identity Data",
    description:
      "e.g. name, username or similar identifier, date of birth, gender, ID number (and full copy of your ID document), tax identification number, photo, nationalities, profession, among others",
  },
  {
    category: "Contact Data",
    description: "e.g. your address, email and phone number",
  },
  {
    category: "Financial Data",
    description: "e.g. Bank account or credit card information",
  },
  {
    category: "Professional Data",
    description:
      "e.g. your role in a company, if you are representing a legal person;",
  },
  {
    category: "Technical Data",
    description: "e.g. your IP, geolocation and browser fingerprint",
  },
  {
    category: "Usage Data",
    description:
      "e.g. how you use our services, details about payments to you and transactions you perform to your selected beneficiaries, including wallet address, amount, currency, type of transaction, source of funds, exchange rate, recipient name and bank details",
  },
  {
    category: "Marketing and Communication Data",
    description: "e.g. your marketing preferences",
  },
  {
    category: "Other",
    description:
      "Any other personal data provided by you in the course of your engagement with TradeBP",
  },
];

const table1 = [
  {
    purpose:
      "To provide the services, the products and manage client relationship",
    typeOfData: [
      "Identity",
      "Contact",
      "Financial",
      "Professional",
      "Technical",
    ],
    lawfulBasis: [
      "Performance of a contract with you",
      "Necessary to comply with a legal obligation",
      "Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)",
    ],
  },
  {
    purpose: "To administer website and the platform",
    typeOfData: ["Identity", "Contact", "Technical", "Usage"],
    lawfulBasis: [
      "Necessary for our legitimate interests (for running our business, enhancing our services and IT services, network security, to prevent fraud and in the context of a business reorganization or group restructuring exercise)",
      "Necessary to comply with a legal obligation",
    ],
  },
  {
    purpose: "For advertisement, data analytics and to provide recommendations",
    typeOfData: [
      "Identity",
      "Contact",
      "Professional",
      "Technical",
      "Usage",
      "Marketing and Communications",
    ],
    lawfulBasis: [
      "Necessary for our legitimate interests (to study how users use our services and website, to develop them, to grow our business and to inform our marketing strategy)",
      "This data is not sold to third parties for any purpose whatsoever",
    ],
  },
  {
    purpose:
      "To comply with internal and external policies, guidelines, rules and legislation",
    typeOfData: ["Identity", "Contact", "Professional", "Technical"],
    lawfulBasis: [
      "Necessary to comply with a legal obligation",
      "Necessary for our legitimate interests (ensure that we run a safe and credible platform and that we remain compliant with all terms and conditions applicable to our license to operate as a virtual asset service provider)",
    ],
  },
];

const PrivacyPolicy = () => {
  const { classes } = useStyles();

  const boldString = (str: string) => {
    return <b>{str}</b>;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    padding: theme.spacing(1.5), // Adjust padding as needed
    whiteSpace: "pre-line", // Preserves whitespace and line breaks
  }));

  const getAlphabeticalSubPoints = (points: any) =>
    points
      .map(
        (point: any, index: any) =>
          `${String.fromCharCode(97 + index)}) ${point} `
      )
      .join("\n");

  const getPrefix = (index: any) => String.fromCharCode(97 + index) + ")";

  return (
    <Box>
      <Grid container className={classes.mainGrid}>
        <Grid item lg={9}>
          <Typography variant="h4" align="center" color="primary">
            TRADEBP Privacy and Cookies policy
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            className={classes.veritcalPadding}
          >
            Last updated: [December 5, 2023]
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            Section A
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            INTRODUCTION
          </Typography>
          <Typography className={classes.parasCenterALign}>
            This privacy policy (“{boldString("Policy")}”) is issued by TradeBP,
            LDA, a Portuguese limited liability company by quotas (“sociedade
            por quotas”) with registered office at Avenida das Comunidades
            Europeias, Edifício Ecuador, no. 396, Loja 500, 2750 - 351 Cascais,
            municipality of Cascais, parish of Cascais e Estoril, registered
            with the Portuguese Commercial Registry Office under the sole
            registration and taxpayer number 516983520, with the share capital
            of EUR 5,000.00 (five thousand euros) (“{boldString("TradeBP")}”).
          </Typography>
          <Typography className={classes.parasCenterALign}>
            In the context of our business, notably for the operation of the
            Website and the Platform and generally in the context of our
            Services, TradeBP will collect and process certain personal data
            from its users, both registered clients and visitor. TradeBP is the
            data controller of the personal data we collect through our Website
            and otherwise as set out in this Policy.
          </Typography>
          <Typography className={classes.parasCenterALign}>
            We do process personal data we collect strictly in compliance with
            this Policy. Therefore, it is very important that you read this
            Policy together with any other policies we may issue regarding the
            collection and processing of personal data, so that you are fully
            aware of how and why we are using your personal data.
          </Typography>
          <Typography className={classes.parasCenterALign}>
            Any capitalized words used and not specifically defined herein shall
            have the same meaning ascribed to them in our General Terms of Use,
            which are available at our website{" "}
            <a target="_blank" href="www.tradebp.com">
              www.tradebp.com
            </a>{" "}
            (“<b>Website</b>” ).
          </Typography>
          <Typography className={classes.parasCenterALign}>
            This Policy is issued in compliance with Regulation (EU) 2016/679 of
            27 April 2016, on the protection of natural persons with regard to
            the processing of personal data and on the free movement of such
            data (“<b>General Data Protection Regulation</b>” or “<b>GDPR</b>”)
            and other legislation that may be issued on a national and European
            level regarding these matters (“<b>Data Protection Laws</b>”).
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            Section B
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            PERSONAL DATA
          </Typography>
          <Typography className={classes.parasCenterALign}>
            This Policy is aimed at informing you about how we handle your
            personal data when you use our Services, visit our Website and use
            the functionalities contained within the same (regardless of where
            you visit it from), to inform you about your privacy rights and how
            the law protects you. In this Policy, you will find more about:
          </Typography>
          <ol>
            <li>
              <Typography>How do we collect your personal data; </Typography>
            </li>
            <li>
              <Typography>What personal data </Typography>
            </li>
            <li>
              <Typography>Why do we process your personal data; </Typography>
            </li>
            <li>
              <Typography>When do we share your personal data; </Typography>
            </li>
            <li>
              <Typography>How do we protect your personal data; </Typography>
            </li>
            <li>
              <Typography>How long do we keep your data; and </Typography>
            </li>
            <li>
              <Typography>
                How can you exercise your data subjects rights.{" "}
              </Typography>
            </li>
          </ol>
          <Typography>
            If you have any questions about this Policy, including any requests
            to exercise of any legal rights, please contact us by email at
            <a href="mailto:info@tradebp.com"> info@tradebp.com</a> or by any
            other means available from time to time.
          </Typography>
          <Typography className={classes.parasCenterALign}>
            <b>1. How do we collect your personal data?</b>
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            We can collect your data in a number of different forms, including:
          </Typography>
          <Box className={classes.ulFlexBox}>
            <Typography>a)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <span className={classes.underLineText}>
                Direct interactions:
              </span>{" "}
              Information you may provide us with directly as part of our
              onboarding process, KYC and KYB processes, and supporting
              information and/or documentation that may be required for the
              purposes of helping us comply with our compliance obligations,
              filling in forms or by corresponding with us by post, phone, email
              or otherwise.
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox}>
            <Typography>b)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <span className={classes.underLineText}>
                Automated technologies or interactions:
              </span>{" "}
              This includes technical data about your equipment, browsing
              actions and patterns that we may automatically collect as you
              interact with our Website, by using cookies and other similar
              technologies.
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox}>
            <Typography>c)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <span className={classes.underLineText}>
                Third parties or publicly available sources:
              </span>{" "}
              This includes personal data about you we received from third
              parties, or that we access in publicly available sources.
            </Typography>
          </Box>
          <Typography className={classes.parasCenterALign}>
            <b>2. What personal data do we collect?</b>
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            2.1. TradeBP does not require its Users to provide any data that
            will have them “identified” or “identifiable”, unless required by
            applicable laws, regulations or in case the Service or Product the
            Client so require.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            In addition, our Website and the Services and Products we offer are
            aimed at persons of legal age and with full legal capacity under
            applicable law. Therefore, we do not request any data from children
            less than 18 years old or persons which do not have full legal
            capacity nor knowingly process any such data. If you are under 18,
            do not use our Services or provide any information to us without
            parental consent. If we learn we have collected or received personal
            information from a child under 18 or from a person without full
            legal capacity, we will delete that information.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            Users may choose to register an account with TradeBP or to create a
            personal registration for any specific Product, and in doing so the
            User will need to provide us data that may have them “identified” or
            “identifiable”. The User may also elect or be required by TradeBP as
            a condition to have access to our Services and Products, to provide
            personal data in the context of a Know Your Customer (“KYC”) or Know
            Your Business (“KYB”) process.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            2.2. Depending on the above, we will collect, use, store and
            undertake other processing activities regarding the following
            personal data, to the extent that such processing is adequate,
            relevant and necessary for the purposes outlined in paragraph (3).
            This may include the following types of personal data:
          </Typography>
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="data table">
              <TableBody>
                {table0.map((row: any, index: any) => (
                  <TableRow key={row.category}>
                    <StyledTableCell component="th" scope="row">
                      <Typography>
                        {getPrefix(index)}
                        <span
                          className={classes.underLineText}
                          style={{ paddingLeft: "10px" }}
                        >
                          {" "}
                          {row.category}
                        </span>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ borderRight: "none" }}>
                      <Typography>{row.description}</Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Typography className={classes.parasCenterALign0}>
            2.3. If a Client is a legal person, besides information and
            documents regarding the entity/company, we may also request the
            aforementioned data regarding its managers, directors, ultimate
            beneficial owners, partners, associates, etc., for the purposes of
            complying with all laws applying to us, notably any AML/CFT laws.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            2.4. We do not collect any special categories of personal data about
            you (e.g. race or ethnicity, religious or philosophical beliefs, sex
            life, sexual orientation, political opinions, trade union
            membership, information about your health, and genetic and biometric
            data). We also do not collect any information about criminal
            convictions and offenses.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            2.5. Through the course of our business relationship, we may ask for
            additional evidence in order for us to comply with our legal
            obligations. These additional evidence can include, but are not
            limited to, documents required to verify any information provided to
            us or evidence of source of your funds and/or or your wealth.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            2.6. The nature of some services requires you to give us personal
            data (e.g., your phone number or e- mail). We may not be able to
            perform the services unless you consent to the processing of such
            data.
          </Typography>
          <Typography className={classes.parasCenterALign}>
            <b>3. Why do we process your personal data?</b>
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            3.1. In the instances where you gave us access to identifiable data,
            we may use it for the following purposes:
          </Typography>
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="data table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Typography variant="h6">
                      <b>Purpose</b>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="h6">
                      <b>Type of data</b>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ borderRight: "none" }}>
                    <Typography variant="h6">
                      <b>Lawful basis for processing</b>
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table1.map((row: any, index: any) => (
                  <TableRow key={index}>
                    <StyledTableCell>
                      <Typography>{row.purpose}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>
                        {getAlphabeticalSubPoints(row.typeOfData)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ borderRight: "none" }}>
                      <Typography>
                        {getAlphabeticalSubPoints(row.lawfulBasis)}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Typography className={classes.parasCenterALign0}>
            3.2. We will only use your personal data for the purposes for which
            we collected it, unless we reasonably consider that we need to use
            it for another reason and that reason is compatible with the
            original purpose and permitted by law.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            3.3. Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules and laws,
            when this is required or permitted by said rules and laws.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            3.4. We will only retain your personal data for as long as
            reasonably necessary to fulfil the purposes we collected it for or
            as established by regulatory or legal requirements. We will only
            retain your personal data for a longer period in the event of a
            complaint or if we reasonably believe there is a prospect of
            litigation in respect to our relationship with you.
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>4. When do we share your information?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            4.1. General
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            TradeBP does not disclose your personal data to third parties,
            except as described in this Policy.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            We might share your data with third parties when needed to perform
            the Services (e.g., we need to communicate your wallet address,
            email and/or name for completing exchange, buy and sell
            transactions) or somehow facilitate the provision of TradeBP’s
            Services, the use of the Platform and the Products (e.g. sharing
            some personal data with third party payment service providers or
            credit card processors in order to allow the completion of the
            operations your instruct in our platform or in order to charge our
            fees).
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            Also, during the onboarding process or in the course of our
            engagement whenever we deemed it required our convenient to comply
            with our regulatory obligations, we may share some personal data
            from our Users with third party service providers we may engage or
            subcontract to perform KYC/KYB tasks.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            We may also disclose your personal data under your instructions, to
            perform the contract entered into by us with you, to protect ours
            and your rights and interests and those of our business partners or
            pursuant to your express consent.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            This is the case when you access our Payment Products. In this case,
            the information we collect on you (including your name, date of
            birth, address, financial data and other) may need to be shared for
            compliance/regulatory reasons (e.g. legal rules ordering the
            collection and retention of certain personal data), to execute your
            order (e.g. communicating to a merchant that you have paid for their
            service or good) and with third party providers that may need to be
            engaged in the process (e.g. credit card processors or other
            financial institutions you use to buy crypto assets).
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            Finally, we may share your personal data, on a confidential basis,
            with subcontractors, for any of the purposes indicated above.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            In this context, please be informed that since we may
            partner/contract with third parties in the context of providing our
            Services, you may also be a customer of such third parties in
            addition to TradeBP. You should review the privacy statements of our
            partners if you would like to know more about the information they
            collect and how do they process it.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            In any event, our third-party service providers are not permitted to
            share or use the personal information we make available to them for
            any other purpose other than to provide us services. In particular,
            we do not allow our third-party service providers to use your
            personal data for their own purposes and only permit them to process
            your personal data for specified purposes and in accordance with our
            instructions.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            TradeBP is not in the business of selling data to third parties for
            profit.{" "}
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            4.2. Disclosure for regulatory reasons{" "}
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            TradeBP operates under the supervision of Banco de Portugal and is
            legally required to comply with certain AML/CFT laws and
            regulations. In this context, we may conduct all inquiries, either
            directly or through third parties, to verify your identity and that
            of your authorized representatives, as we may deem required or
            convenient to ensure full compliance with the applicable laws.{" "}
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            Your personal data will only be disclosed to law enforcement
            authorities, or other government bodies, to the extent required by
            laws and regulations.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            In any event, TradeBP will not ordinarily share your personal data
            with public authorities (including law enforcement), unless we are
            required to do so under applicable law and/or by an appropriate
            legal instrument (e.g. a warrant, a court decision, an order or
            administrative instruction from a regulatory authority having
            authority and jurisdiction over TradeBP). Such disclosure will, in
            any event, be limited to the minimum required to comply with our
            legal and regulatory obligations.{" "}
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>5. How do we protect your personal data?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            We are committed to protecting the privacy and confidentiality of
            your personal data. Access to your data is limited only to
            authorized TradeBP officers, employees, contractors or others who
            may require access to it – on a strictly need to know basis – in
            order to perform the Services requested by you or as required by
            applicable law and regulations.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            In addition, we have put in place appropriate security measures to
            prevent your personal data from being accidentally lost, used, or
            accessed in an unauthorized way, altered, or disclosed. More
            specifically, we have implemented the following security measures:
          </Typography>

          <Box className={classes.ulFlexBox1}>
            <Typography>a)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Staff dedicated to cyber and physical security, that designs,
              implements and provides oversight to our information security
              program;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>b)</Typography>
            <Typography className={classes.ulFlexTExt}>
              The use of specialized technology such as host-based security
              tools, network defense monitors, and intrusion detection systems;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>c)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Testing of the security and operability of products and services
              before they are introduced to the Internet, as well as ongoing
              scanning for publicly known vulnerabilities in the technology;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>d)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Internal and external reviews of our Internet website and
              services;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>e)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Monitoring of our systems infrastructure to detect weaknesses and
              potential intrusions;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>f)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Implementing controls to identify, authenticate and authorize
              access to various systems or sites;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>g)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Protecting information during transmission through various means
              including, where appropriate, encryption; and
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>h)</Typography>
            <Typography className={classes.ulFlexTExt}>
              Providing our personnel with relevant training and continually
              updating our security practices in light of new risks and
              developments in technology.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterALign0}>
            Although we take your privacy very seriously and strive to implement
            the most efficient ways to protect your right to privacy and
            confidentiality over your data, we cannot fully guarantee the
            security of information collected or transmitted electronically. We
            have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>6. How long do we keep your personal data?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            We will keep your personal data for the time needed to fulfill the
            purpose for which it was collected.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            If you have an account or a personal registration with us, we will
            keep your data collected in relation to your account activity or the
            activity of your personal registration for as long as the account or
            the personal registration exists.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            In order to comply with certain legal obligations, namely
            obligations under the AML/CFT laws, TradeBP is required to keep your
            personal data for at least 7 years.{" "}
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>7. How can you exercise your rights?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            Under certain circumstances, you have the following rights under the
            data protection laws in relation to your personal data:
          </Typography>

          <Box className={classes.ulFlexBox1}>
            <Typography>a)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <b>Request access, correct or erase your personal data: </b>
              This enables you to receive a copy of the personal data we hold
              about you and to check that we are lawfully processing it, correct
              any inaccuracies, complete any information you believe is
              incomplete or request (to the extent permitted by law) the
              deletion of your data.
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>b)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <b>Object to the processing of your personal data: </b>
              You can object to processing if we are relying on a legitimate
              interest (or those of a third party) and there is something about
              your particular situation which makes you want to object to
              processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. However, you can not object to
              the processing if said processing arises from a legal obligation
              or where we must process your information to satisfy a contract to
              which you are a party (for example, to provide you with our
              Services).
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>c)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <b>Restrict processing of your personal data: </b>
              This enables you to ask us to suspend the processing of your
              personal data in the following scenarios:
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox2}>
            <Typography>o</Typography>
            <Typography className={classes.ulFlexTExt}>
              If you want us to establish the data's accuracy;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox2}>
            <Typography>o</Typography>
            <Typography className={classes.ulFlexTExt}>
              Where our use of the data is unlawful, but you do not want us to
              erase it;
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox2}>
            <Typography>o</Typography>
            <Typography className={classes.ulFlexTExt}>
              Where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims; or
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox2}>
            <Typography>o</Typography>
            <Typography className={classes.ulFlexTExt}>
              You have objected to our use of your data, but we need to verify
              whether we have overriding legitimate grounds to use it.{" "}
            </Typography>
          </Box>

          <Box className={classes.ulFlexBox1}>
            <Typography>d)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <b>Right to withdraw your consent: </b>
              This allows you to withdraw consent at any time where we are
              relying solely on consent to process your personal data.
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox1}>
            <Typography>e)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <b>Right to Data Portability: </b>
              This enables you to request the transfer of your personal data to
              you or to a third party.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterALign0}>
            If you wish to exercise any of the rights set out above, please
            contact us in writing to{" "}
            <a href="mailto:info@tradebp.com">info@tradebp.com</a>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            Please note that when legal exceptions apply, some or all of these
            rights may not be enforceable. This will be the case, for example,
            if we have reason to suspect you may have been using our Services,
            our Product or out Platform for unlawful purposes. In these cases,
            we can either refuse to, for example, erase your personal data and
            transactions record, or agree to do it within a certain reasonable
            time period that would allow us to confirm or waive our suspicions.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            When exercising your rights, we may need to request specific
            information from you to help us confirm your identity and ensure
            that personal data is not disclosed to any person who has no right
            to receive it. We may also contact you to ask you for further
            information in relation to your request to speed up our response.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is unreasonable, repetitive or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances.
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>8. Miscellaneous</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            8.1. Reviews and changes: Our Privacy Policy is reviewed regularly
            to ensure that any new obligations and technologies, as well as any
            changes to our business operations and practices, are taken into
            consideration, as well as that it remains in accordance with the
            changing regulatory environment. Any personal information we hold
            will be governed by our most recent Privacy Policy. We are entitled
            to amend this Policy whenever necessary. Every time an amend takes
            place, the updated Policy will be duly published in public website.{" "}
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            8.2. Complaints: If you have a complaint about this Policy or any
            element of how we use your personal data, then please contact us
            first at info@tradebp.com. If you are not satisfied and are located
            in an EEA country, then please contact your local data protection
            authority. You have the right to present a formal complaint. In
            Portugal said authority is CNPD – Comissão Nacional de Protecção de
            Dados, and its website is: https://www.cnpd.pt/
          </Typography>
          <Typography className={classes.parasCenterALign0}>
            If you are based in, or the issue you would to complain about took
            place in the EEA, please visit this website
            (https://edpb.europa.eu/aboutedpb/board/members_en) for a list of
            local data protection authorities in other EEA countries.
          </Typography>

          <Typography
            variant="h6"
            align="center"
            color="primary"
            sx={{ paddingTop: "20px" }}
          >
            Section C
          </Typography>
          <Typography variant="h6" align="center" color="primary">
            COOKIES
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>1. What are cookies?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            For our Website to work properly, we sometimes may place cookies in
            your device. A cookie is a small text file that a website stores on
            your computer or mobile device when you visit the site. Cookies
            contain information that is transferred to your computer's hard
            drive are not used to identify you personally.
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            Every time you visit our Website you will be prompted to accept or
            refuse cookies.
          </Typography>

          <Typography className={classes.parasCenterALign}>
            <b>2. How do we use cookies?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            We use the following cookies:
          </Typography>

          <Box className={classes.ulFlexBox}>
            <Typography>a)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <span className={classes.underLineText}>
                Strictly necessary cookies
              </span>{" "}
              These cookies are necessary for the website to function and cannot
              be switched off in our systems. They are usually only set in
              response to actions made by which amount to a request for
              services, such as setting your privacy preferences, logging in or
              filling in forms. You can set your browser to block or alert you
              about these cookies, but some parts of the site will not when
              work. These cookies do not store any personally identifiable
              information.
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox}>
            <Typography>b)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <span className={classes.underLineText}>
                Analytical/performance cookies
              </span>{" "}
              They allow us to recognize and count the number of visitors and to
              see how visitors move around our website when they are using it.
              This helps us to improve the way our website works, for example,
              by ensuring that users are finding what they are looking for
              easily. All information these cookies collect is aggregated and
              therefore anonymous.
            </Typography>
          </Box>
          <Box className={classes.ulFlexBox}>
            <Typography>c)</Typography>
            <Typography className={classes.ulFlexTExt}>
              <span className={classes.underLineText}>
                Functionality cookies
              </span>{" "}
              These cookies enable the website to provide enhanced functionality
              and personalization. They may be set by us or by a third-party
              provider whose services we have added to our pages. If you do not
              allow these cookies some services may not function properly.
            </Typography>
          </Box>

          <Typography className={classes.parasCenterALign}>
            <b>3. How can my cookie settings be changed?</b>
          </Typography>

          <Typography className={classes.parasCenterALign0}>
            Your web browser may allow some control of cookies through the
            browser settings. However, if you use your browser settings to block
            all cookies (including essential cookies) you may not be able to
            access all or parts of our site. Learn more about cookies, including
            how to see what cookies have been set and how to manage and delete
            them, at www.allaboutcookies.org.{" "}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrivacyPolicy;

const useStyles = makeStyles()((theme) => {
  return {
    ulFlexTExt: {
      paddingLeft: "10px",
    },
    underLineText: {
      textDecorationLine: "underline",
      paddingRight: "10px",
    },
    ulFlexBox1: {
      display: "flex",
      flexDirection: "row",
      padding: "10px 0",
    },
    ulFlexBox2: {
      display: "flex",
      flexDirection: "row",
      padding: "10px 0px 10px 40px",
    },
    ulFlexBox: {
      display: "flex",
      flexDirection: "row",
    },
    parasCenterALign0: {
      padding: "10px 0",
    },
    parasCenterALign: {
      padding: "20px 0",
    },
    mainGrid: {
      ...styles.flexDRC,
      padding: "50px 20px",
    },
    veritcalPadding: {
      padding: "20px 0",
    },
    veritcalPadding0: {
      padding: "20px 0",
    },
  };
});

import {makeStyles, styles} from "app/styles";


export const useDefaultTableComponentStyles = makeStyles()((theme) => {
    return {
        tableHeader: {
            padding: "10px 0 20px 0px",
        },

        tableFooter: {
            padding: "50px 0 10px 0px",
        },

        tableComponentBox: {
            // padding: "12px",
            // margin: "20px 0px",
            // borderRadius: "8px",
            // boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
        },

        tableTitleBox: {
            ...styles.flexDRS,
        },
        tableTitle: {
            textTransform: "capitalize",
            color: "#36205fc7",
        },
        commonCardsTable: {
            padding: "12px",
            borderRadius: "8px",
            boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
            width: "100%",
            margin: "20px 0",
        },

        tablePluginBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
        },

        paginationComponent: {
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-selectIcon": {
                color: "rgba(0, 0, 0, 0.54)",
            },
            "& .MuiTablePagination-displayedRows": {
                color: "rgba(0, 0, 0, 0.54)",
            },
            "& .MuiTablePagination-actions": {
                color: "rgba(0, 0, 0, 0.54)",
            },
        },

        paginationBox:{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px",
        },
        searchBoxPlugin: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px",
        },

        downloadCsvPluginBtn: {
            textTransform: "none",
            margin: "0 10px",
            paddingLeft: "10px",
            '&:hover': {
                backgroundColor: theme.palette.info.main,
                color:"white"
            }
        },


    };
});


export const useTableSearchPluginStyles = makeStyles()((theme) => {
    return {
        searchBox: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px",
        },
    };
});

export const useTableDownloadCsvPluginStyles = makeStyles()((theme) => {
    return {
        downloadCsvBtn: {
            textTransform: "none",
            margin: "0 10px",
        },
        iconWeb: {
            height: "24px",
            width: "24px",
        },

    };
});

import React, {useState} from "react";
import {LoadingButton, LoadingButtonProps} from "@mui/lab";
import {MuiIcon} from 'app/components/common';


export type T_LoaderBtnProps = LoadingButtonProps & {
    text: string;
    leftIcon?: boolean;
    iconName?: string;
};



export const LoaderButton = ({text,iconName, ...opt}: T_LoaderBtnProps):React.JSX.Element => {
    const btnIcon = opt?.leftIcon ? "startIcon": "endIcon";

    const sx = {
        margin: "10px",
        padding: "10px 20px",
        ...(opt?.sx || {}),
    };

    const loadingBtnProps: LoadingButtonProps = {
        variant: "contained",
        loadingPosition: opt?.leftIcon ? "start" : "end",
        loading: opt?.loading,
        disabled: opt?.loading || opt?.disabled,
        [btnIcon]:  <MuiIcon iconName={iconName}/>,
        ...(opt || {}),sx,
    };

    return (<LoadingButton {...loadingBtnProps} ><span>{text}</span></LoadingButton>);
};
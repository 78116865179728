import {pallete, styles,makeStyles} from "app/styles";


export const useInputFieldStyles = makeStyles()((theme) => {
    return {
        securityBox: {
            padding: "50px 0",
        },
        label: {
            // Custom styles for the label
            color: "#B9B9B9 !important", // Change label text color
            // fontSize: '1rem', // Change font size
            "&.Mui-focused": {
                color: "blue", // Color of the label when the input is focused
            },
        },
        errorMsg: {
            color: "red",
            paddingLeft: "5px",
        },
        inputBox: {
            width: "100%",
            margin: "10px 0",
            // border: "1px solid #FFFFFF",
        },
        formBox: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            position: "relative",
        },
        secImgFooter: {
            height: "50px",
            width: "130px",
            marginRight: "20px",
        },
    };
});

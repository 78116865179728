import React from "react";
import {Box, Tab, Tabs, Typography,} from "@mui/material";
import {headerBoxStyles} from "./Styles";


export type T_CommonTabProps = {
    classes?: any;
    keyprefix: string;
};

export  type T_TabData = {
    label: string;
    component: React.ReactNode;
}


export type T_BasicTabProps = {
    tabHeading: string;
    tabs: T_TabData[];
    activeTabValue?: number;
    resetUrlOnTabChange?: boolean;

}

export type T_BasicTabMenuProps = T_CommonTabProps & {
    index: number;
    name: string;
    value: number;
}

export type I_BasicTabPanelProps = T_BasicTabMenuProps & {
    children?: React.ReactNode;
}


export const TabContentHeading = (heading: string, headingClass: any) => {
    return (<Typography variant="h4" color="primary" className={headingClass}>{heading || ''}</Typography>);
}

export const TabContentPanel = (props: I_BasicTabPanelProps): React.JSX.Element => {
    const {children, value, index, classes, ...other} = props;
    const isActive = value === index;
    const keyprefix: string = `${props.keyprefix}-tab-pannel-${index}`;
    const tabPanelProps = {
        role: "tabpanel",
        hidden: !isActive,
        id: `simple-tabpanel-${index}`,
        "aria-labelledby": `simple-tab-${index}`,
        ...other
    };
    return (
        <Box {...tabPanelProps} key={`${keyprefix}-box-wrapper`}>{
            isActive && (
                <Box sx={{p: 3}} key={`${keyprefix}-box-wrapper-content-child`}>
                    <Typography>{children}</Typography>
                </Box>
            )
        }</Box>
    );
}

export const TabContentMenu = ({index, name, value, classes, keyprefix}: T_BasicTabMenuProps): React.JSX.Element => {
    const tabMenusProps = {
        "id": `basic-tab-${index}`,
        "aria-controls": `basic-tabpanel-${index}`,
        "key": `${keyprefix}-tab-menu-${index}`
    }
    return (<Tab label={name} {...tabMenusProps} className={classes.tabsText} />);
}


export type T_TabContentViewProps = T_CommonTabProps & {
    tabHeading: string;
    tabMenuList: React.JSX.Element[];
    tabPanelList: React.JSX.Element[];
    activeTabIndex: React.JSX.Element;
    changeActiveTab: (event: React.SyntheticEvent, newValue: number) => any;
}


export const TabContentView = ({classes, keyprefix, ...opt}: T_TabContentViewProps): React.JSX.Element => {
    return (
        <Box className={classes.mainBox}>
            <Box sx={{width: '100%'}} key={`${keyprefix}-box-container`}>
                <Box sx={headerBoxStyles} key={`${keyprefix}-box-wrapper`}>
                    { TabContentHeading(opt.tabHeading, classes.basicTabHeading)}
                    <Tabs value={opt.activeTabIndex} onChange={opt.changeActiveTab} aria-label="basic tabs ">
                        {opt.tabMenuList}
                    </Tabs>
                </Box>
                {opt.tabPanelList}
            </Box>
        </Box>
    );
}

/**
 * Imports the required modules and components from React and Material-UI
 * React: The core React library
 * useState: A React hook for managing state
 * Box, Card, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography: Material-UI components
 * useDefaultTableContainerStyles: A custom hook for styling the table container
 */
import React from "react";
import {useDefaultTableComponentStyles} from "./TableStyles";
import {Box, Card, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";

/**
 * Imports all the required fragments (components or utilities) from the './fragments' module
 */
import {TableBodyFragments as TBF} from "./fragments";
import {TDF} from "./index";


/**
 * Defines the type for the props of the TableComponent
 * T_TableComponentProps: An interface that extends T_TableRowDataProps, T_TableHeadingProps, and includes additional properties
 * Tdata: A generic type parameter representing the data type of the table rows
 */
export type T_DefaultTableViewProps = {

    // Indicates whether the table is in a loading state or not
    isLoading: boolean | undefined;

    tableBodyContentProps: TBF.T_TableContentProps;
    // The title of the table
    titleProps: TBF.T_TableTitleProps;

    headingProps: TBF.T_TableHeadingsProps;

    pluginProps?: TBF.T_TablePluginProps;

    paginatorProps?: TBF.T_TablePaginatorProps;

    rowActionPluginProps?: TDF.T_TableRowActionProps;

    tableBreadcrumbsProps?: TBF.T_TableBreadcrumbsProps;
}


/**
 * The main TableComponentView
 * It renders a table with pagination, search, and CSV download options (if provided)
 * It takes in props of type T_TableComponentProps and returns a JSX.Element
 */

export const DefaultTableView = (opt: T_DefaultTableViewProps): React.JSX.Element => {
    const {classes} = useDefaultTableComponentStyles();

    return (
        <Box className={classes.tableComponentBox}>

            {opt.tableBreadcrumbsProps && <TBF.TableBreadcrumbs {...opt.tableBreadcrumbsProps} />}

            <Card className={classes.commonCardsTable}>

                <Box sx={{flexGrow: 1}} className={classes.tableHeader}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TBF.TableTitle {...opt.titleProps} classes={classes} />
                        </Grid>
                        <Grid item xs={8}>
                            {opt.pluginProps && <TBF.TablePlugins {...opt.pluginProps} />}
                        </Grid>

                    </Grid>
                </Box>


                <TableContainer component={Paper} sx={{boxShadow: "none"}}>
                    <Table sx={{minWidth: 650}} aria-label="customized table">
                        <TableHead sx={{backgroundColor: "#F7F6F9"}}>
                            <TableRow>
                                <TBF.TableHeadings {...opt.headingProps} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TBF.TableContent {...opt.tableBodyContentProps} />
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box className={classes.tableFooter}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>

                        </Grid>
                        <Grid item xs={8}>
                            {opt.paginatorProps && <TBF.TablePaginator {...opt.paginatorProps} />}
                        </Grid>
                    </Grid>

                </Box>

            </Card>
        </Box>
    )
}

import React, {useState} from "react";
import {T_UseCryptohubPaymentState} from "./CryptohubPaymentHooks";
import {Box, Button, IconButton, TableBody, TableCell, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {T_TransactionDetail, TransactionDetailsForm} from "./TransactionDetailsForm"
import Alert from "@mui/material/Alert";
import {useCompleteCryptohubPaymentRequest} from "./CryptohubPaymentQueries";

import {useLoader, useNotification} from "app/common";
import {redirectTo} from "app/utils";

type T_ViewProps = {
    classes: any;
    paymentState: T_UseCryptohubPaymentState;
    transactionId: string | any;
};


type T_TransactionDetailsListProps = {
    transactionDetails: T_TransactionDetail[];
    handleDeleteTransactionDetail: (index: number) => void;
}


const TransactionDetailsList = ({transactionDetails, ...args}: T_TransactionDetailsListProps): React.JSX.Element[] => {

    if (transactionDetails.length > 0) {
        return transactionDetails.map((walletDetail: T_TransactionDetail, index: number) => (
            <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell>{walletDetail.type}</TableCell>
                <TableCell>{walletDetail.tx_hash}</TableCell>
                <TableCell>{walletDetail.quantity.toFixed(6).replace(/\.?0+$/, "")}</TableCell>
                <TableCell align="right">
                    <IconButton aria-label="delete" color={"error"}
                                onClick={() => args.handleDeleteTransactionDetail(index)}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
        ));
    }


    return [0].map(() => (
        <TableRow key={"#noWalletsMessage"} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell colSpan={3}>
                <Alert severity="info">
                    <b>No transaction details are selected yet!</b>
                    <br/>
                    Fill up the transaction details below and click on "Add" button, to add to list.
                    <br/> <br/>
                    <i>You need to provide at least 1 transaction detail to proceed further.</i>
                    {/*<br/><br/>*/}
                    {/*<Link href={"https://www.binance.com/en-IN/square/post/1094681"} target="_blank">*/}
                    {/*    How do i find last 6 digits of wallet address?*/}
                    {/*</Link>*/}
                </Alert>

            </TableCell>
        </TableRow>
    ))
}


export const TransactionCompletedView = ({classes, paymentState, transactionId}: T_ViewProps): React.JSX.Element => {
    const [transactionDetails, setTransactionDetails] = useState<T_TransactionDetail[]>([]);
    const completeTransactionRequest = useCompleteCryptohubPaymentRequest();
    const notification = useNotification();
    const loader = useLoader();


    const handleAddTransactionDetail = (tWalletDetail: T_TransactionDetail) => {
        setTransactionDetails(prevAddresses => [...prevAddresses, tWalletDetail]);
    }

    const handleDeleteTransactionDetail = (walletIndex: number) => {
        setTransactionDetails(transactionDetails.slice(0, walletIndex).concat(transactionDetails.slice(walletIndex + 1)));
    }

    const handleOnSubmit = async () => {
        loader.show();
        const payload = {
            "pk": `${paymentState.transactionLink?.transaction_id}` || "",
            "transactions": transactionDetails.map((data) => (
                {
                    [data.type === "source" ? "source_address" : "tx_hash"]: data.tx_hash,
                    quantity: data.quantity.toFixed(6).replace(/\.?0+$/, "")
                }
            ))
        };
        await completeTransactionRequest.EXECUTE({
            payload,
            onSuccessFn: (data, message): void => {
                notification.success(message);
                redirectTo({url: data.redirect_url});
            },
            onErrorFn: (error, message): void => notification.error(message),
        }).finally(() => {
            loader.hide();
        });

    };


    return (
        <Box className={classes.mainCard} sx={{minWidth: "680px"}}>
            <Typography gutterBottom variant="h5" component="div" align={"center"}>
                Provide Transaction Details
            </Typography>

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Transaction hash / Source Wallet Address</TableCell>
                        <TableCell>Asset Quantity</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TransactionDetailsList {...{transactionDetails, handleDeleteTransactionDetail}} />
                </TableBody>
            </Table>

            <Box className={classes.walletForm}>
                <TransactionDetailsForm onSubmitHandler={handleAddTransactionDetail}/>
            </Box>

            <Button
                className={classes.doneButton} sx={{marginTop: 5,}}
                onClick={handleOnSubmit}
                disabled={!transactionDetails.length}
            > Submit
            </Button>
        </Box>
    );
};

import React from "react";
import {Box, Skeleton,} from "@mui/material";
import {StyledTableCell, StyledTableRow} from "./TableCommonFragments";
import MenuItem from "@mui/material/MenuItem";


export const TableHeadingSkeleton = (keyPrefix: string): React.JSX.Element => {
    return (<Skeleton key={`${keyPrefix}-TH-SKEL`} variant="rounded" height={'2rem'} width={'100%'} />)
}


export const TableColumnSkeleton = (rowKey: number, columnCount: number): React.JSX.Element[] => {
    const tableCells: React.JSX.Element[] = [];

    for (let i = 0; i < columnCount; i++) {
        const keyPrefix: string = `#T-SKEL-R${rowKey}-C${i}`;
        tableCells.push(
            <StyledTableCell key={`${keyPrefix}-TD-C${i}`} keyPrefix={keyPrefix}>
                <Skeleton key={`${keyPrefix}-T-SKEL-C${i}`} variant={'text'} sx={{fontSize: '1rem'}} />
            </StyledTableCell>
        );
    }
    return tableCells;

}

export const TableRowsSkeleton = (rowCount: number, columnCount: number): React.JSX.Element[] => {
    const tableRows: React.JSX.Element[] = [];

    for (let i = 0; i < rowCount; i++) {
        const keyPrefix: string = `#T-SKEL-ROW-${i}`;
        tableRows.push(<StyledTableRow keyPrefix={keyPrefix}>{TableColumnSkeleton(i, columnCount)}  </StyledTableRow>);
    }
    return tableRows;

}

export const TablePaginationSkeleton = (keyPrefix: string): React.JSX.Element => {
    const key: string = `${keyPrefix}-PGN-SKEL`;
    const commonProps = {height: "1.5rem", sx: {margin: "5px"}};
    return (
        <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row"}}>
            <Skeleton {...commonProps} variant={"rounded"} key={`${key}-1`} width={'150px'} />
            <Skeleton {...commonProps} variant={"rounded"} key={`${key}-2`} width={'40px'} />
            <Skeleton {...commonProps} variant={"rounded"} key={`${key}-3`} width={'60px'} />
            <Skeleton {...commonProps} variant={"rounded"} key={`${key}-4`} width={'25px'} />
            <Skeleton {...commonProps} variant={"rounded"} key={`${key}-5`} width={'25px'} />
        </Box>
    )
}



/**
 * Skeleton component for TablePlugins.
 */
export type T_TablePluginsSkeletonProps = {
    keyPrefix: string;
    height?: string;
    margin?: string;
    width?: string;
}

export const TablePluginsSkeleton = ({keyPrefix, ...opt}: T_TablePluginsSkeletonProps): React.JSX.Element => {
    return (
        <Skeleton
            height={(opt?.height || "2.5rem")}
            sx={{margin: (opt?.margin || "5px")}}
            variant={"rounded"}
            key={`${keyPrefix}-PLUGIN-SKL`}
            width={(opt?.width || "150px")}
        />
    )
}

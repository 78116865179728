import React from "react";
import {FORM, YUP, validationSchema} from "app/components/inputs";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {Divider} from "@mui/material";


const schema = YUP
    .object({
        email: validationSchema.email(),
        company: YUP.string().required("Last Name is required"),
        age: YUP.number().positive("Age should be a positive")
            .typeError("Age Should be a number.")
            .min(18, "Min Age is 18")
            .required("Age Is Required."),
    }).required()


interface I_SignUpRequestFormValues {
    email: string
    company: string
    mobile: number
}


export const SignupRequestForm = (): React.JSX.Element => {

    const onSubmitHandler = (values: any) => {
        setTimeout(() => console.log({values}), 2000);
    }
    const signUpForm = FORM.useForm({
        name: "Sign Up Request Form",
        validationSchema: schema,
        defaultValues: {email: "", company: "", mobile: "",},
        onSubmitHandler,
    });


    return (<>


        <FORM.Provider form={signUpForm.instance} heading={"Raise a sign up request."}>

            <Alert severity="info">
                <AlertTitle><b>Disclaimer</b></AlertTitle>
                This is a warning Alert with a cautious title.
            </Alert>


            <FORM.Input
                {...signUpForm.bind({name: "email", label: "Email Id"})}
                inputOptions={{fullWidth: true}}
            />

            <FORM.Input
                {...signUpForm.bind({name: "company", label: "Company Name"})}
                inputOptions={{fullWidth: true}}
            />

            <FORM.Input
                {...signUpForm.bind({name: "email", label: "Email Id"})}
                inputOptions={{fullWidth: true, type: "number"}}
            />
            <Divider/>
            {/*import SendOutlinedIcon from '@mui/icons-material/SendOutlined';*/}
            <FORM.SubmitButton
                text={"I am ready to be onboarded"}
                iconName={"Send"}
                formInstance={signUpForm.instance}
                fullWidth={true}
            />

        </FORM.Provider>

    </>);
};




